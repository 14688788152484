// 越南语
export const lang = {
	bannerTitle: "Đặc quyền VIP",
	bannerInfo01: "Nâng cấp thành viên VIP",
	bannerInfo02: "nhận tiền thưởng và tận hưởng các loại khuyến mãi hấp dẫn",
	language_code: "ngôn ngữ",
	Game: "Trò chơi",
	Member: "Thành viên",
	Bonus: "Thưởng",
	Name: "Tên",
	query: "Tìm kiếm",
	vip_query: "Yêu cầu VIP",
	home_page: "Nhà",
	preferential_activities: "Khuyến mãi",
	online_service: "Dịch vụ khách hàng",
	viplevel_query: "Yêu cầu cấp độ VIP ",
	fill_member_account: "Tên đăng nhập",
	vip_level: "Từ {msg}, mỗi khoản tiền gửi hợp lệ của bạn sẽ được hệ thống tự động tích lũy. Khi số tiền tích lũy đạt đến một tiêu chuẩn nhất định, nó có thể được tự động nâng cấp lên cấp VIP cao hơn. Cấp VIP càng cao, tham gia càng nhiều khuyến mãi và càng nhận được nhiều tiền thưởng. W69 sẽ tiếp tục tung ra nhiều chương trình khuyến mãi tặng thưởng cho các thành viên mới và cũ.",
	vip_level_tip: "Cược hợp lệ của bạn trong ngày sẽ được tính vào ngày mốt, và cấp độ VIP của bạn sẽ được cập nhật và tiền thưởng khuyến mãi sẽ được phát hành vào thời điểm đó.",
	vip_level_tip1: "Cược hợp lệ của bạn trong ngày sẽ được tính vào ngày mốt, và cấp độ VIP của bạn sẽ được cập nhật và tiền thưởng khuyến mãi sẽ được phát hành vào thời điểm đó.",
	vip_t_exam: "Thí dụ",
	vip_exam: "LƯU Ý: Thời gian thống kê dữ liệu được tính dựa trên gmt + 8 time.",
	vipTh: [
		"Cấp độ VIP",
		"Tiền gửi",
		"Phần thưởng nâng cấp VIP",
		"Phần thưởng VIP của lễ hội",
		"Phần thưởng sinh nhật VIP",
		// "Ưu đãi tiền gửi hàng tháng",
		"Phần thưởng may mắn VIP",
		"VIP thưởng âm hàng tháng",
		// "Tên miền độc quyền VIP",
		"Dịch vụ khách hàng 24 giờ"
	],
	queryTh: [
		"nâng cấp vip",
		"Khuyến mãi may mắn",
		"Tiền thưởng sinh nhật",
		"Thưởng ngày lễ",
		"Trợ cấp lợi nhuận âm",
		// "cung cấp tiền gửi hàng tháng",
		"Dịch vụ miền độc quyền",
	],
	tabData: [
		"Đặc quyền thành viên",
		// "Tiền thưởng may mắn ",
		// "Ưu đãi tiền gửi hàng tháng",
		"Mô tả đặc quyền",
	],
	query_result: "tìm kêt quả",
	dear: "kính thưa",
	now_viplevel: "cấp độ VIP hiện tại",
	current_active_bets: "cược hợp lệ hiện tại",
	current_save_sum: " tổng số tiền gửi",
	next_bet_need: "yêu cầu đặt cược",
	next_need_save: "yêu cầu đặt cọc",
	level_after_promotion: "Cấp độ sau khi nâng cấp ",
	tab2_p1: "Cách đăng ký chiết khấu tiền gửi hàng tháng",
	tab2_p2: "Thành viên chỉ cần có 3 lần gửi tiền từ 100 trở lên hoặc 1 lần gửi tiền và hồ sơ cá cược từ 3000 trở lên trong tuần trước mỗi lần nộp đơn, và gửi tiền lại để đủ điều kiện tham gia. (Ưu đãi chỉ có giá trị sau khi nạp tiền và không áp dụng trước trận đấu!) Thông tin chi tiết như sau:",
	tab1Th: ["Cấp độ VIP", "Tiền gửi hàng tháng", "Chúc may mắn tiền thưởng"],
	tab2Th: ["Cấp độ VIP", "Đề nghị đặt cọc", "Số lượng đơn đăng ký", "Giới hạn chiết khấu", "Yêu cầu rút tiền"],
	tab1_p1: "Làm thế nào để đăng ký Giải thưởng May mắn？",
	tab1_p2: "Giải thưởng may mắn: Không cần đăng ký. Hệ thống sẽ được phát hành lần lượt sau 06:00 ngày 5 hàng tháng (GMT + 8). Do số lượng giải thưởng lớn nên việc nhận thưởng có thể bị chậm trễ. Hãy kiên nhẫn chờ đợi nếu nó không được nhận trong thời gian.",
	tab1_p2_u: "Phần thưởng may mắn: Hệ thống sẽ tính vào cuối mỗi ngày 5 của tháng. Đường dây liên hệ trong vòng 5-10 ngày để gửi yêu cầu thưởng.",
	tab1_p3: "Thành viên cần đạt được cấp độ VIP tương ứng và số tiền gửi hiệu quả theo yêu cầu trước khi có thể nhận được số tiền may mắn tương ứng.",
	tab1_p4: "Ví dụ: Cấp độ VIP của thành viên trong tháng hiện tại là VIP3, và số tiền gửi có hiệu lực trong tháng hiện tại là 5000. Tháng tiếp theo chỉ có thể nhận được 99 tiền may mắn tương ứng với cấp độ VIP 3.",

	tab3_p1: "Làm thế nào để đăng ký khuyến mãi VIP?",
	tab3_p2: "Nếu số tiền nạp hợp lệ đạt cấp tương ứng, bạn có thể tự động nâng cấp lên cấp VIP cao hơn. Phần thưởng khuyến mãi sẽ được phát vào khoảng 02:00 ngày hôm sau (GMT + 8).",
	tab3_p3: "Làm thế nào để đăng ký thưởng lãi âm hàng tháng?",
	tab3_p4: "Nếu lợi nhuận âm hàng tháng của thành viên đạt từ 3000VND trở lên thì không cần áp dụng chiết khấu này. Hệ thống sẽ gửi tiền thưởng tương ứng cho bạn vào ngày 15 hàng tháng. Không có giới hạn trên cho số tiền thưởng. Có thể nhận được tối đa 5% tiền thưởng chuyển nhượng của công ty.",
	tab3_p5: "Làm thế nào để áp dụng cho một món quà sinh nhật?",
	tab3_p6: "Hãy liên hệ với dịch vụ khách hàng trực tuyến để cung cấp chứng chỉ hợp lệ và chuyên gia tổ chức sự kiện sẽ gửi cho bạn phần thưởng tương ứng vào ngày sinh nhật của bạn!",
	tab3_p7: "",
	tab3_p8: "Làm cách nào để yêu cầu một món quà ngày lễ?",
	tab3_p9: "Không cần nộp hồ sơ, thành viên chỉ cần nạp tiền hợp lệ trên 2000VND trong vòng 15 ngày trước lễ hội là đủ điều kiện và hệ thống sẽ gửi cho bạn phần thưởng tương ứng vào các ngày Tết Dương lịch, Tết Nguyên đán, Lễ Lao động. và Ngày Quốc khánh.",
	tab3_p9_u: "",
	tab3_p10: "Làm thế nào để đăng ký nhận tiền thưởng may mắn hàng tháng?",
	tab3_p11: "Chỉ cần nạp 1000VND hàng tháng, hệ thống sẽ tự động phát thưởng may mắn vào ngày 5 của ngày hôm sau. Chúc may mắn và tài lộc.",
	tab3_p12: "",
	tab3_p13: "",
	tab3_p14: "Kính gửi các thành viên VIP: Xin chân thành cảm ơn sự ủng hộ không ngừng của các bạn và công ty. Miễn là bạn là VIP5 trở lên, bạn có thể đăng ký một tên miền độc quyền tùy chỉnh và tận hưởng các đặc quyền sang trọng của tên miền VIP giải trí W69",
	tab3_p15: "Phần thưởng ứng trước: liên hệ với bộ phận chăm sóc khách hàng của LINE để đăng ký, hệ thống sẽ hoàn tất quá trình truyền vào ngày 10 hàng tháng (GMT + 8), đồng thời liên hệ với bộ phận chăm sóc khách hàng của LINE để áp dụng trong ngày 10 đến ngày 15. Do số lượng thưởng lớn, có thể có sự chậm trễ trong việc đến tài khoản. Nếu bạn không đến kịp, vui lòng liên hệ với chúng tôi nhiều lần!",
	tab3_p16: "Phần thưởng bị mất: liên hệ với bộ phận chăm sóc khách hàng của LINE để đăng ký, hệ thống sẽ hoàn tất quá trình truyền vào ngày 15 hàng tháng (GMT + 8), đồng thời liên hệ với bộ phận chăm sóc khách hàng của LINE từ ngày 15 đến ngày 20. Nếu lợi nhuận âm hàng tháng của thành viên đạt hơn 3.000 nhân dân tệ, công ty có thể nhận được quà tặng cao nhất từ ​​công ty Thưởng chuyển khoản 5%, không có giới hạn trên số tiền thưởng.",


	wan: "Mười nghìn",
	qianwan: "10 triệu",
	yi: "100 triệu",
	qianyi: "100 tỉ",
	wanyi: "nghìn tỷ",
	qianwanyi: "triệu tỷ",
	bei: " ×1",
	vipprivileges: " đặc quyền",
	no_account: "không tài khoản",
	// 手机端
	officialIndex: "Nhà",
	VIPquery: "Yêu cầu VIP",
	specialOffer: "Khuyến mãi",
	onlineCustomerService: "Dịch vụ khách hàng",
	Line: "Hàng",
	//搜索
	VIPLevelQuery: "Yêu cầu VIP",
	PleaseInputAccount: "số tài khoản",
	Query: "Tìm kiếm",
	//tab切换1
	MemberAdvantage: "Đặc quyền thành viên ",
	MemberContent: "Từ {msg}, mọi cược hợp lệ bạn thực hiện sẽ được hệ thống tích lũy tự động. Khi số tiền tích lũy đạt đến một tiêu chuẩn nhất định, nó có thể tự động được nâng cấp lên cấp VIP cao hơn, cấp VIP càng cao thì tham gia càng nhiều hoạt động ưu đãi và càng nhận được nhiều tiền thưởng. Nền tảng sẽ tiếp tục tung ra nhiều hoạt động ưu đãi hơn nữa để trao thưởng cho các thành viên mới và cũ.",
	MemberTips: "Ví dụ: Thành viên a đã tích lũy 100.000 cược hiệu quả kể từ khi mở tài khoản và có thể được thăng hạng lên VIP3 (có thể nhận được 8 + 18 + 38 + 88 = 152 giải khuyến mãi), và 300.000 cược hiệu quả có thể được tạo để được thăng hạng vip4 ( 8 + 18 + 38 + 88 + 188 = 340 tiền thưởng khuyến mãi), cược hợp lệ được tạo ra càng nhiều, cấp VIP càng cao, và bạn càng có thể hưởng nhiều chiết khấu và đặc quyền VIP!",
	VIPLevel: "Cấp độ VIP",
	CumulativeEffectiveBetting: "Số tiền đặt cọc",
	PromotionFiled: "Phần thưởng nâng cấp VIP",
	HolidayFiled: "Phần thưởng VIP của lễ hội",
	BirthdayHandsel: "Phần thưởng sinh nhật VIP",
	MonthlyDiscount: "Ưu đãi tiền gửi hàng tháng",
	LuckyMoney: "Phần thưởng may mắn VIP",
	NegativeGold: "VIP thưởng âm hàng tháng",
	ExclusiveDomainName: "Tên miền độc quyền VIP",
	DedicatedService: "Dịch vụ khách hàng 24 giờ",
	//tab切换2
	LuckyMoneyTit: "Tiền thưởng may mắn ",
	HowApplyLuckyMoney: "Làm thế nào để đăng ký Giải thưởng May mắn？",
	LuckyContent1: "Giải thưởng may mắn: Không cần đăng ký. Hệ thống sẽ được phát hành lần lượt sau 06:00 ngày 5 hàng tháng (GMT + 8). Do số lượng giải thưởng lớn nên việc nhận thưởng có thể bị chậm trễ. Hãy kiên nhẫn chờ đợi nếu nó không được nhận trong thời gian.",
	LuckyContent1_u: "Phần thưởng may mắn: Hệ thống sẽ tính vào cuối mỗi ngày 5 của tháng. Đường dây liên hệ trong vòng 5-10 ngày để gửi yêu cầu thưởng.",
	LuckyContent2: "Thành viên cần đạt được cấp độ VIP tương ứng và số tiền gửi hiệu quả theo yêu cầu trước khi có thể nhận được số tiền may mắn tương ứng.",
	LuckyContent3: "Ví dụ: Cấp độ VIP của thành viên trong tháng hiện tại là VIP3 và số tiền gửi có hiệu lực cho tháng hiện tại là 100.000. Tháng tiếp theo chỉ có thể nhận được 88 tiền cầu may tương ứng với VIP cấp 3.",
	LuckyLevel: "Cấp độ VIP",
	MonthlyDeposit: "Tiền gửi hàng tháng có hiệu lực",
	DowryGoodLuck: "Giải thưởng may mắn",
	//tab切换3
	LavishGifts: "Ưu đãi tiền gửi hàng tháng",
	HowApplyMonthlyDepositDiscount: "Cách đăng ký chiết khấu tiền gửi hàng tháng？",
	LavishContent: "Thành viên chỉ cần có 3 lần gửi tiền từ 100 trở lên hoặc 1 lần gửi tiền và hồ sơ cá cược từ 3000 trở lên trong tuần trước mỗi lần nộp đơn, và gửi tiền lại để đủ điều kiện tham gia. (Ưu đãi chỉ có giá trị sau khi nạp tiền và không áp dụng trước trận đấu!) Thông tin chi tiết như sau:",
	Lavishlevel: "Cấp độ VIP",
	softLoan: "Đề nghị đặt cọc",
	NumberRequested: "Số lượng đơn đăng ký",
	OfferOnline: "Giới hạn chiết khấu",
	withdrawals: "Yêu cầu rút tiền",
	//tab切换4
	PrivilegeThat: "Mô tả đặc quyền",
	HowMonthlyNegativeInterestTranshipmentPayment: "Cách đăng ký thanh toán chuyển khoản âm hàng tháng？",
	PrivilegeContent1: "Nếu lợi nhuận âm hàng tháng của thành viên đạt từ 3.000 THB trở lên, bạn có thể nhận được khoản thưởng chuyển khoản lên đến 5% từ công ty. Giảm giá này không cần phải áp dụng. Hệ thống sẽ gửi tiền thưởng tương ứng cho bạn vào ngày 15 hàng tháng. Không có giới hạn trên về số tiền thưởng.",
	PrivilegeContent2: "Làm thế nào để đăng ký quà tặng sinh nhật？",
	PrivilegeContent3: "Hãy liên hệ với dịch vụ khách hàng trực tuyến để cung cấp chứng chỉ hợp lệ và chuyên gia tổ chức sự kiện sẽ gửi cho bạn phần thưởng tương ứng vào ngày sinh nhật của bạn!",
	PrivilegeContent4: "（Để đảm bảo việc gửi tiền thắng được diễn ra bình thường, vui lòng liên hệ với chuyên viên chăm sóc khách hàng để điền đầy đủ thông tin ngày sinh!）",
	PrivilegeContent5: "Làm thế nào để đăng ký quà tặng ngày lễ？",
	PrivilegeContent6: "Quy tắc xổ số: Thành viên chỉ cần thực hiện một khoản tiền gửi hợp lệ hơn 2000 trong vòng 15 ngày trước kỳ nghỉ để đủ điều kiện tham gia xổ số.",
	PrivilegeContent6_u: "Quy tắc xổ số: Thành viên chỉ cần thực hiện một khoản tiền gửi hợp lệ hơn 2000 trong vòng 15 ngày trước kỳ nghỉ để đủ điều kiện tham gia xổ số.",
	PrivilegeContent7: "Về dịch vụ 24 giờ",
	PrivilegeContent8: "Chúng tôi cung cấp cho bạn dịch vụ khách hàng 24 * 7 giờ liên tục, gửi và rút tiền trực tuyến và cá cược trò chơi. Nếu bạn cần giúp đỡ, xin vui lòng tư vấn dịch vụ khách hàng trực tuyến 24 giờ.",
	PrivilegeContent9: "Tên miền độc quyền VIP là gì？",
	PrivilegeContent10: "Các thành viên trên cấp độ VIP3 có thể tham khảo ý kiến của chuyên gia đường dây để gửi đơn đăng ký.",
	PrivilegeContent11: 'Tham gia vào các hoạt động VIP của Nền tảng có nghĩa là bạn đồng ý với "Các Quy tắc và Điều khoản Ưu đãi Chung',
	//弹窗
	QueryResult: "Kết quả",
	Exalted: "Thân mến：",
	YourCurrentVIPLevel: "mức vip hiện tại：",
	CurrentActiveBets: "cược hợp lệ hiện tại：",
	CurrentActBets: "tổng số tiền gửi：",

	BetsAreNeededQualify: "yêu cầu đặt cược",
	VIPLevelAfterPromotion: "Cấp độ sau khi nâng cấp",
	Close: "Đóng",
	Birthday: "Tiền thưởng sinh nhật",
	Holiday: "Thưởng ngày lễ",
	Negative: "trợ cấp lợi nhuận âm",
	Monthly: "tiền gửi hàng tháng ",
	exclusiveLine: "Miền độc quyền",
	exclusiveService: "Dịch vụ miền độc quyền",
	noData: "không có dữ liệu",
	privilege: " đặc ân",
	myriad: "Mười nghìn",
	AHundredMillion: "100 triệu",
	double: " ×1",
	changeLanguage: "Ngôn ngữ",
	click_collection: "Bộ sưu tập bằng một cú nhấp chuột",
	get_title: "Kính gửi những người chơi Nền tảng, chúc mừng bạn đã nhận được",
	get_fail: "Không xác nhận được! Vui lòng thử lại sau",
	concern: "xác nhận",
	tipBottom: "Ví dụ: Thành viên A có thể được thăng hạng lên VIP4 (bạn có thể nhận được 9 + 19 + 39 + 99 + 199 = 365 tiền thưởng khuyến mãi) kể từ khi mở tài khoản, số tiền tích lũy đạt 30000, số tiền gửi hợp lệ tích lũy càng nhiều, mức VIP càng cao, và đồng thời có thể tận hưởng Sẽ có nhiều chiết khấu và đặc quyền vip hơn!",
	tipBottom02: "Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với dịch vụ khách hàng trực tuyến của nền tảng",
	tipBottom03: "Tỷ lệ số tiền Việt Nam: 1: 1000",
	promotionType: {
		1: "Nâng cấp tiền thưởng",
		2: "Tiền thưởng ngày lễ",
		3: "Tiền thưởng sinh nhật",
		4: "Ưu đãi tiền gửi hàng tháng",
		5: "Giải thưởng may mắn",
		6: "Trợ cấp lợi nhuận âm"
	}
}
