// 泰语
export const lang = {
	bannerTitle: "สิทธิพิเศษวีไอพี",
	bannerInfo01: "อัพเกรดสมาชิกวีไอพี",
	bannerInfo02: "รับโบนัสและเพลิดเพลินกับโปรโมชั่นที่น่าสนใจมากมาย",
  language_code: "เลือกภาษา",
  Game:"Game",
  Member:"Member",
  Bonus:"Bonus",
  Name:"Name",
  query: "ค้นหา",
  vip_query: "ค้นหาVIP",
  home_page: "โปรโมชั่น",
  preferential_activities: "กิจกรรมโปรโมชั่น",
  online_service: "เจ้าหน้าที่ออนไลน์",
  viplevel_query: "ค้นหา VIP",
  fill_member_account: "กรุณาป้อนยูสเซอร์เนม",
  vip_level: "ตั้งแต่วันที่ {msg} ยอดเงินฝากของคุณจะถูกสะสมอัตโนมัติโดยระบบ เมื่อยอดสะสมถึงที่กำหนด จะสามารถอัพเกรดเป็นวีไอพีระดับสูงได้โดยอัตโนมัติ ยิ่งระดับ VIP สูงขึ้น คุณก็จะเข้าร่วมโปรโมชั่นได้มากขึ้น และโบนัสที่คุณได้รับก็จะมากขึ้น W69 จะมีโปรโมชั่นออกมาเรื่อยๆเพื่อตอบแทนสมาชิกใหม่และสมาชิกเก่า ",
  vip_level_tip: "ระบบจะอัพเดตระดับ VIP พร้อมให้โบนัสอัพเกรดอัตโนมัติหลังจากที่ยอดเทิร์นของสมาชิกถูกคำนวนแล้ว (ยอดเทิร์นที่นำมาอัพเกรดจะถูกคิด 2 วันหลังจากที่สมาชิกได้ทำการเดิมพัน )",
  vip_level_tip1: "ระบบจะอัพเดตระดับ VIP อัตโนมัติหลังจากที่ยอดเทิร์นของสมาชิกถูกคำนวนแล้ว (ยอดเทิร์นที่นำมาอัพเกรดจะถูกคิด 2 วันหลังจากที่สมาชิกได้ทำการเดิมพัน )",
  vip_t_exam: "ตัวอย่าง",
  vip_exam: "หมายเหตุ: คำนวณตามเวลา GMT+8",
  vipTh: [
    "ระดับVIP",
    "ยอดฝาก",
    "โบนัสอัพเกรด",
    "โบนัสเทศกาล",
    "โบนัสวันเกิด",
    // "โปรโมชั่นการฝากทุกเดือน",
    "โบนัสนำโชค",
    "โบนัสยอดเสีย VIP",
    // "โดเมนพิเศษ",
    "เจ้าหน้าที่ให้บริการ"
  ],
  queryTh: [
    "โบนัสอัพเกรด",
    "โบนัสนำโชค",
    "โบนัสวันเกิด",
    "โบนัสช่วงเทศกาล",
    "โบนัสยอดเสีย",
    // "โบนัสเงินฝากรายเดือน",
    // "โดเมนพิเศษ",
    "บริการลูกค้าโดเมน"
  ],
  tabData: [
    "ข้อดีของ VIP",
    // "โบนัสโชคดี",
    // "โบนัสเงินฝาก",
    "คำอธิบาย",
  ],
  query_result: "ผลลัพธ์",
  dear: "ยูสเซอร์เนมสมาชิก",
  now_viplevel: "ขั้นระดับ vip",
  current_active_bets: "ยอดเทิร์นโอเวอร์ปัจจุบัน",
  current_save_sum: "ยอดฝากทั้งหมด",
  next_bet_need: "ยอดเทิร์นโอเวอร์ที่ต้องทำ",
  next_need_save: "ระดับต่อไปยังเหลือยอดเงินที่ต้องฝาก",
  level_after_promotion: "หลังอัพเกรด VIP",
  tab2_p1: "วีธีการสมัครโปรโมชั่นสำหรับการฝาก?",
  tab2_p2: "สมาชิกจะต้องมีการฝากตั้งแต่ 100 ขึ้นไป 3 รายการ หรือ 3000 ขึ้นไป 1 รายการภายใน 1 อาทิตย์ก่อนขอรับโปรโมชั่น และทำการฝากอีกครั้งเพื่อขอรับโปรโมชั่น   (ขอสมัครโปรโมชั่นนี้หลังจากฝากและก่อนเข้าเล่นเกมส์!) รายละเอียดดังนี้ :",
  tab1Th: ["ระดับVIP","เงินฝากรายเดือน","โบนัสนำโชค"],
  tab2Th: ["ระดับVIP","โบนัสเงินฝาก","จำนวนครั้ง","โบนัสสูงสุด","เทิร์นโอเวอร์"],
  tab1_p1: "วีธีการสมัครโบนัสนำโชค?",
  tab1_p2: "ระบบจะแจกโบนัสนำโชคอัตโนมัติโดยไม่ต้องทำการสมัครอย่างต่อเนื่องหลังเวลา06.00 น. (GMT+8) ในวันที่ 5 ของทุกเดือนตามเวลา เนื่องจากโบนัสมีจำนวนมาก อาจมีความล่าช้าในการทำรายการ โปรดอดทนรอหากไม่ได้รับในเวลา",
  tab1_p2_u: "โบนัสนำโชค :  ระบบจะคำนวณสิ้นสุดทุกวันที่5ของเดือน ติดต่อ line ระยะเวลาภายในวันที่ 5-10 เพื่อยื่นคำขอรับโบนัส",
  tab1_p3: "สมาชิกต้องอยู่ในระดับVIPและจำนวนเงินฝากที่กำหนดก่อน ถึงจะได้รับโบนัสนำโชคที่ที่กำหนดไว้ค่ะ",
  tab1_p4: "ตัวอย่าง : สมาชิกระดับ VIP3 มีเงินฝากรายเดือนปัจจุบันคือ 5000 เดือนถัดไปจะได้รับโบนัสจำนวน 99 THBตามระดับVIPของสมาชิก",
  
  tab3_p1: "วีธีการสมัครโบนัสอัพเกรด?",
  tab3_p2: "หากมียอดฝากถึงกำหนด ระบบจะอัพvipอัตโนมัติ โบนัสจะจัดส่งออก 02:00 ของวันถัดไป ( GMT+8)",
  tab3_p3: "วิธีสมัครโบนัสยอดเสีย",
  tab3_p4: "หากสมาชิกมียอดเสียมากว่า3000บาทขึ้นไป ตั้งแต่วันที่ 1-15ของแต่ละเดือน โบนัสจัดส่งให้อัตโนมัติวันที่ 16ของทุกเดือน โบนัสสูดสุด 5% จำนวนไม่จำกัด",
  tab3_p5: "วิธีการสมัคโบนัสวันเกิด",
  tab3_p6: "ติดต่อฝ่ายบริการลูกค้าออนไลน์เพื่อยืนยันหลักฐาน หลังจากที่ตรวจสอบเรียบร้อยเจ้าหน้าที่จะส่งโบนัสให้คุณในวันเกิดของคุณ",
  tab3_p7: "(เพื่อความสะดวกในการดำเนินรายการ กรุณาติดต่อเจ้าหน้าที่เพื่อกรอกข้อมูลวันเกิดให้ครบถ้วน)",
  tab3_p8: "วิธีการสมัครโบนัสเทศกาล?",
  tab3_p9: "หลักเกณฑ์การแจก: ต้องมียอดฝาก 2000 ขึ้นไปก่อนวันเทศกาล15วัน ก็มีสิทธิ์เข้าร่วมการแจกโบนัส โดยวันที่เข้าร่วมกิจกรรม วันปีใหม่/ วันตรุษจีน / วันสงกานต์ / วันลอยกระทง / วันแรงงาน",
  tab3_p9_u: "หลักเกณฑ์การแจก: ทางU31จะเป็นฝ่ายชี้แจ้งว่าเทศกาลไหนถึงจะเข้าร่วมได้รับโบนัสเทศกาลนี้ (จะมีประกาศหน้าเว็บไซต์) ต้องมีการเดิมพัน 2000 ขึ้นไปก่อนวันเทศกาล15วัน ก็มีสิทธิ์เข้าร่วมการแจกโบนัส",
  tab3_p10: "",
  tab3_p11: "",
  tab3_p12: "วิธีการสมัครโบนัสนำโชค?",
  tab3_p13: "หากยอดฝากสะสม 1000 ขึ้นไป สามารถรับโบนัสนำโชคได้ในเดือนถัดไป ระบบจัดส่งโบนัสทุกวันที่ 5 ของเดือน",
  tab3_p14: "เรียนสมาชิก vip : ขอบคุณสำหรับการสนับสนุนของท่านมาตลอด หากvip5 ขึ้นไปคุณสามารถขอรับ โดเมน ได้",
  tab3_p15: "โบนัสอัพเกรด :  ระบบจะคำนวณสิ้นสุดทุกวันที่10ของเดือน ติดต่อ line ระยะเวลาภายในวันที่ 10-15  เพื่อยื่นคำขอรับโบนัส",
  tab3_p16: "โบนัสยอดเสีย : ระบบจะคำนวณสิ้นสุดทุกวันที่15ของเดือน   สมาชิกที่มียอดเสียมากกว่า3000THBขึ้นไป ติดต่อ line ระยะเวลาภายในวันที่ 15-20  เพื่อยื่นคำขอรับโบนัส รับสูงสุดถึง5% ไม่จำกัดโบนัส",
  wan: "หมื่น",
  qianwan: "สิบล้าน",
  yi: "ร้อยล้าน",
  qianyi: "หลายแสนล้าน",
  wanyi: "ล้านล้าน",
  qianwanyi: "สี่พันล้าน",
  bei: " ×1",
  vipprivileges: "สิทธิพิเศษ",
  no_account: "ไม่พบบัญชีสมาชิก โปรดป้อนใหม่",
  // 手机端
  officialIndex: "โปรโมชั่น",
  VIPquery: "ค้นหาวีไอพี",
  specialOffer: "กิจกรรมพิเศษ",
  onlineCustomerService: "เจ้าหน้าที่ออนไลน์",
  Line: "Line",

  //搜索
  VIPLevelQuery: "ค้นหา VIP",
  PleaseInputAccount: "กรุณาป้อนยูสเซอร์เนม",
  Query: "ค้นหา",


  //tab切换1
  MemberAdvantage: "ข้อดีของ VIP",
  MemberContent: " ตั้งแต่วันที่ {msg} เป็นต้นไป ยอดเทิร์นโอเวอร์ของสมาชิกจะถูกรวบรวมและจัดเก็บเพื่อเป็นข้อมูลในการอัพเกรดเป็นระดับวีไอพีต่างๆ ระดับ VIP ยิ่งสูงมากเท่าไหร่ก็จะสามารถเข้าร่วมโปรโมชั่นต่างๆและโบนัสได้มากขึ้นเท่านั้น  เพื่อตอบแทนสมาชิกที่ให้การสนับสนุนตลอดมา อย่าลืมติดตาม แพลตฟอร์ม เพื่อรอรับโปรโมชั่นใหม่ๆได้เลยนะคะ",
  MemberTips: "ตัวอย่าง:  สมาชิก A ตั้งแต่เปิดบัญชีมียอดเทิร์นโอเวอร์สะสมตั้งแต่เปิดบัญชี 100000 สามารถอัพเกรดเป็นvip3ได้ รับโบนัสอัพเกรดรวมตั้งแต่vip0-vip3เป็นจำนวนรวมทั้งหมด（8+18+38+88=152）หากยอดเทิร์นโอเวอร์ถึง 300000 สามารถอัพเกรดเป็น vip4 ได้ รับโบนัสอัพเกรดรวมตั้งแต่vip0-vip4เป็นจำนวนรวมทั้งหมด（8+18+38+88+188=340）หากมียอดเทิร์นโอเวอร์มากขึ้นก็จะสามารถอัพเกรดระดับvipได้สูงยิ่งขึ้นและ สามารถรับส่วนลดและสิทธิพิเศษระดับ VIP ได้มากขึ้น!",
  VIPLevel:"ระดับVIP",
  CumulativeEffectiveBetting:"ยอดเทิร์นโอเวอร์",
  PromotionFiled:"โบนัสอัพเกรด",
  HolidayFiled:"โบนัสช่วงเทศกาล",
  BirthdayHandsel:"โบนัสวันเกิด",
  MonthlyDiscount:"โปรโมชั่นการฝากทุกเดือน",
  LuckyMoney:"โบนัสนำโชค",
  NegativeGold:"โบนัสยอดเสีย",
  ExclusiveDomainName:"โดเมนพิเศษ",
  DedicatedService:"เจ้าหน้าที่ให้บริการ",


  //tab切换2
  LuckyMoneyTit: "โบนัสโชคดี",
  HowApplyLuckyMoney: "วีธีการสมัครโบนัสนำโชค?",
  LuckyContent1: "ระบบจะแจกโบนัสนำโชคอัตโนมัติโดยไม่ต้องทำการสมัครอย่างต่อเนื่องหลังเวลา 06.00 น. (GMT+8) ในวันที่ 5 ของทุกเดือนตามเวลา เนื่องจากโบนัสมีจำนวนมาก อาจมีความล่าช้าในการทำรายการ โปรดอดทนรอหากไม่ได้รับในเวลา",
  LuckyContent1_u: "โบนัสนำโชค :  ระบบจะคำนวณสิ้นสุดทุกวันที่5ของเดือน ติดต่อ line ระยะเวลาภายในวันที่ 5-10 เพื่อยื่นคำขอรับโบนัส",
  LuckyContent2: "สมาชิกต้องอยู่ในระดับVIPและจำนวนเงินฝากที่กำหนดก่อน ถึงจะได้รับโบนัสนำโชคที่ที่กำหนดไว้ค่ะ",
  LuckyContent3: "ตัวอย่าง : สมาชิกระดับ VIP3 มีเงินฝากรายเดือนปัจจุบันคือ 100000 เดือนถัดไปจะได้รับโบนัสจำนวน 88 THBตามระดับVIPของสมาชิก",
  LuckyLevel: "ระดับVIP",
  MonthlyDeposit: "เงินฝากรายเดือน",
  DowryGoodLuck: "โบนัสนำโชค",


  //tab切换3
  LavishGifts: "โบนัสเงินฝาก",
  HowApplyMonthlyDepositDiscount: "วีธีการสมัครโปรโมชั่นสำหรับการฝาก?",
  LavishContent: "สมาชิกจะต้องมีการฝากตั้งแต่ 100 ขึ้นไป 3 รายการ หรือ 3000 ขึ้นไป 1 รายการภายใน 1 อาทิตย์ก่อนขอรับโปรโมชั่น และทำการฝากอีกครั้งเพื่อขอรับโปรโมชั่น   (ขอสมัครโปรโมชั่นนี้หลังจากฝากและก่อนเข้าเล่นเกมส์!) รายละเอียดดังนี้ :",
  Lavishlevel: "ระดับVIP",
  softLoan: "โบนัสเงินฝาก",
  NumberRequested: "จำนวนครั้ง",
  OfferOnline: "โบนัสสูงสุด",
  withdrawals: "เทิร์นโอเวอร์",


  //tab切换4
  PrivilegeThat: "คำอธิบาย",
  HowMonthlyNegativeInterestTranshipmentPayment: "โบนัสยอดเสียประจำเดือน?",
  PrivilegeContent1: "เมื่อสมาชิกมียอดเสียตั้งแต่ 3000 THBขึ้นไป/เดือนและอยู่ในระดับวีไอพีที่เข้าร่วม จะได้รับโบนัสช่วยเหลือเพิ่มดวงจากทางเราสูงสุด 5% ระบบจะแจกโบนัสอัตโนมัติในวันที่ 15 ของทุกเดือน ไม่จำกัดจำนวนโบนัส",
  PrivilegeContent2: "วีธีการสมัครโบนัสวันเกิด?",
  PrivilegeContent3: "ติดต่อฝ่ายบริการลูกค้าออนไลน์เพื่อยืนยันหลักฐาน หลังจากที่ตรวจสอบเรียบร้อยเจ้าหน้าที่จะส่งโบนัสให้คุณในวันเกิดของคุณ",
  PrivilegeContent4: "(เพื่อความสะดวกในการดำเนินรายการ กรุณาติดต่อเจ้าหน้าที่เพื่อกรอกข้อมูลวันเกิดให้ครบถ้วน)",
  PrivilegeContent5: "วิธีการสมัครโบนัสเทศกาล",
  PrivilegeContent6: "หลักเกณฑ์การแจก: ต้องมีการเดิมพัน 2000  ขึ้นไปก่อนวันเทศกาล15วัน ก็มีสิทธิ์เข้าร่วมการแจกโบนัส",
  PrivilegeContent6_u: "หลักเกณฑ์การแจก: ทางU31จะเป็นฝ่ายชี้แจ้งว่าเทศกาลไหนถึงจะเข้าร่วมได้รับโบนัสเทศกาลนี้ (จะมีประกาศหน้าเว็บไซต์) ต้องมีการเดิมพัน 2000 ขึ้นไปก่อนวันเทศกาล15วัน ก็มีสิทธิ์เข้าร่วมการแจกโบนัส",
  PrivilegeContent7: "เกี่ยวกับการให้บริการตลอด 24 ชั่วโมง",
  PrivilegeContent8: "หากท่านพบปัญหาเกี่ยวกับ การฝาก-ถอน หรือมีข้อสงสัยสามารถติดต่อเจ้าที่ได้ตลอด เรามีเจ้าหน้าที่ให้บริการตลอด 24 ชั่วโมง",
  PrivilegeContent9: "โดเมน VIP คืออะไร?",
  PrivilegeContent10: "เมื่อสมาชิกเป็น VIP3 ขึ้นไป  คุณสามารถติดต่อเจ้าหน้าที่ทางไลน์เพื่อขอรับโดเมนส่วนตัว",
  PrivilegeContent11: "ข้อความข้างต้นเป็นข้อกำหนดและเงื่อนไขทั่วไป ของ แพลตฟอร์ม",



  //弹窗
  QueryResult: "ผลการค้นหา",
  Exalted: "ผู้มีเกียรติ:",
  YourCurrentVIPLevel: "ระดับวีไอพีในปัจจุบันของคุณ:",
  CurrentActiveBets: "ยอดเทิร์นโอเวอร์ปัจจุบัน:",
  CurrentActBets: "ยอดฝากทั้งหมด:",
  BetsAreNeededQualify: "ยอดเทิร์นโอเวอร์ที่ต้องทำ",
  VIPLevelAfterPromotion: "หลังอัพเกรด VIP",
  Close: "ปิดกล้อง",
  Birthday: "โบนัสวันเกิด",
  Holiday: "โบนัสช่วงเทศกาล",
  Negative: "โบนัสยอดเสีย",
  Monthly: "โบนัสเงินฝากรายเดือน",
  exclusiveLine: "โดเมนพิเศษ",
  exclusiveService: "บริการลูกค้าพิเศษ",
  noData: "ไม่พบบัญชีสมาชิก โปรดป้อนใหม่",
  privilege: "สิทธิพิเศษระดับ",
  myriad: "ประดามี",
  AHundredMillion: "พันล้าน",
  double: " ×1",
  changeLanguage: "ภาษา",

  // qianyi:"พันล้าน",
  // wanyi:"ล้านล้าน",
  // qianwan:"พันล้าน",
  // wan:"ประดามี",
  // yi:"พันล้าน",
  // qianwanyi:"พันล้าน",
  click_collection: "รับทั้งหมด",
  get_title: "ขอแสดงความยินดีกับสมาชิก แพลตฟอร์ม",
  get_fail: "การรับล้มเหลว กรุณาทำรายการใหม่ภายหลัง",
  concern: "ยืนยัน",
  tipBottom:"ตัวอย่าง : สมาชิก A มียอดฝากตั้งแต่เปิดบัญชี 30,000 สามารถอัพเป็น VIP4 ได้ รับโบนัสอัพเกรดรวมตั้งแต่ VIP0 - VIP4 เป็นจำนวนรวมทั้งหมด （9+19+39+99+199 = 365） ยอดเทิร์นสะสมยิ่งสูง VIP ยิ่งสูงตาม พร้อมกันนี้ยังมีส่วนลดและสิทธ์พิเศษระดับดับ VIP อีกมากมายที่คุณสามารถเพลิดเพลินได้",
  tipBottom02:"มีปัญหาเพิ่มเติมติดต่อเจ้าหน้าที่",
  tipBottom03:"อัตราส่วนปริมาณประเทศไทย: 1:1",
  promotionType: {
    1: "โบนัสอัพเกรด",
    2: "โบนัสช่วงเทศกาล",
    3: "โบนัสวันเกิด",
    4: "โปรโมชั่นการฝากทุกเดือน",
    5: "โบนัสนำโชค",
    6: "โบนัสยอดเสีย"
  }
}