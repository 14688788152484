import Vue from 'vue';
import axios from 'axios';
// import {
//   Indicator
// } from 'mint-ui';
// Vue.component(Indicator);

// create an axios instance
// let baseUrl = 'http://api.hyjco.top/';
// let baseUrl = 'http://api.hbgrzgjx.com/';
// let baseUrl = 'https://api.superorder.co/';
let baseUrl = 'https://api.lookover78899.com';

let hostUrl = location.host;
let protocol = location.protocol;
let newUrl = hostUrl.split('.');
if(newUrl.length>2){
  newUrl[0] = "api";
}else{
  newUrl.unshift('api');
}
let newBaseUrl1 = protocol+'//'+newUrl.join('.');
console.log('newBaseUrl:',newBaseUrl1);

// let baseUrl = newBaseUrl1;

// let baseUrl = 'http://192.168.0.50:8083';
export function baseu() {
  return baseUrl;
}
console.log(baseUrl);
const service = axios.create({
  baseURL: baseUrl, // api的base_url
  headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    // 'Access-Control-Allow-Headers': '*'
  },
  timeout: 5000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // Do something before request is sent
    // if (store.getters.token) {
    // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    // config.headers['token'] = ''
    // }
    return config;
  },
  error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// respone interceptor
service.interceptors.response.use(
  config => {
    // Indicator.close();
    return config;
  },
  error => {
    console.log(error);
    // Indicator.close();
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = '错误请求';
          break;
        case 401:
          error.message = '未授权，请重新登录';
          break;
        case 403:
          error.message = '拒绝访问';
          break;
        case 404:
          error.message = '请求错误,未找到该资源';
          break;
        case 500:
          error.message = '服务器端出错';
          break;
        default:
          error.message = `连接错误${error.response.status}`;
      }
    } else {
      error.message = '连接到服务器失败';
    }
    return Promise.reject(error.message);
  }
);

export default service;