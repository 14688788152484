
export const gameDatas = {
    th:[
      { 0: "go***8", 1: "", 2: "ZEUS", 3: "11,052" },
      { 0: "al***23", 1: "", 2: "Brothers kingdom", 3: "54,415" },
      { 0: "bo***990", 1: "", 2: "5 Fortune Dragons", 3: "101,141" },
      { 0: "b***i20", 1: "", 2: "Golden Monkey", 3: "13,045" },
      { 0: "ons***789", 1: "", 2: "Fafafa", 3: "14,025" },
      { 0: "u7u***2549", 1: "", 2: "Joker's Treasure", 3: "45,325" },
      { 0: "p***27", 1: "", 2: "Cai Shen 888", 3: "14,025" },
      { 0: "s***hui", 1: "", 2: "Money Mouse", 3: "10,560" },
      { 0: "kk***1995", 1: "", 2: "FaFaFa2", 3: "18,235" },
      { 0: "naru***2534", 1: "", 2: "Candy Pop", 3: "50,426" },
      { 0: "kan***asin", 1: "", 2: "Ho Yeah Monkey", 3: "12,999" },
      { 0: "m***th", 1: "", 2: "Double Flame", 3: "18,745" },
      { 0: "sat***ath", 1: "", 2: "Book of Myth", 3: "16,824" },
      { 0: "jne***11", 1: "", 2: "First Love", 3: "17,050" },
      { 0: "lerd***n11", 1: "", 2: "Dancing Fever", 3: "10,520" },
      { 0: "z***uhai", 1: "", 2: "Lucky Koi,", 3: "60,110" },
      { 0: "sulk***law", 1: "", 2: "Mega 7", 3: "16,524" },
      { 0: "par***1896", 1: "", 2: "Lucky Cai Shen", 3: "12,245" },
      { 0: "aw***528", 1: "", 2: "Heroes", 3: "17,542" },
      { 0: "kk***70", 1: "", 2: "Baby Cai Shen", 3: "17,050" },
      { 0: "do***290", 1: "", 2: "Golden Lotus SE", 3: "14,597" },
      { 0: "ba***48", 1: "", 2: "First Love", 3: "15,020" },
      { 0: "jelen***539", 1: "", 2: "Mayan Gems", 3: "15,407" },
      { 0: "krit***jkl40", 1: "", 2: "Princess Wang", 3: "16,547" },
      { 0: "an***a", 1: "", 2: "Pineapple Poker", 3: "14,050" },
      { 0: "lol***8", 1: "", 2: "Fiery Sevens", 3: "13,045" },
      { 0: "ch***at", 1: "", 2: "Dragon Empire", 3: "201,125" },
      { 0: "ble***82128", 1: "", 2: "Three Lucky Stars", 3: "24,251" },
      { 0: "so***30", 1: "", 2: "Sweet Bakery", 3: "131,040" },
      { 0: "a***ong", 1: "", 2: "Magical Lamp", 3: "145,971" },
      { 0: "kav****25", 1: "", 2: "Gold Panther", 3: "17,050" },
      { 0: "kl***ey55", 1: "", 2: "Triple Panda", 3: "11,052" },
      { 0: "ae***ed", 1: "", 2: "Mr Chu Tycoon", 3: "11,052" },
      { 0: "s***sit", 1: "", 2: "Jungle King", 3: "42,050" },
      { 0: "bo***k25", 1: "", 2: "168 Fortunes", 3: "69,999" },
      { 0: "ye***30", 1: "", 2: "Wow Prosperity", 3: "10,560" },
      { 0: "ons***d789", 1: "", 2: "Golden Chicken", 3: "12,235" },
      { 0: "ka9***gnid", 1: "", 2: "Gangster Axe", 3: "17,050" },
      { 0: "suti***2535", 1: "", 2: "Cai Yuan Guang Jin", 3: "10,560" },
      { 0: "chi***wat", 1: "", 2: "Da Fu Xiao Fu", 3: "15,210" },
      { 0: "de***65", 1: "", 2: "Pan Fairy", 3: "41,254" },
      { 0: "jakkra***0093", 1: "", 2: "Highway Fortune", 3: "114,104" },
      { 0: "aej***99", 1: "", 2: "Big Prosperity SA", 3: "17,050" },
      { 0: "ladd***oden", 1: "", 2: "chompoo", 3: "17,050" },
      { 0: "toey***rvive", 1: "", 2: "Tiger Warrior", 3: "47,452" },
      { 0: "xx***16", 1: "", 2: "Double Fortunes", 3: "10,502" },
      { 0: "ch***26", 1: "", 2: "Golden Whale", 3: "10,105" },
      { 0: "lad***den", 1: "", 2: "Alibaba", 3: "16,652" },
      { 0: "zx***55", 1: "", 2: "Fist of Gold", 3: "17,050" },
      { 0: "bu***i20", 1: "", 2: "Emperor Gate SA", 3: "11,052" },
      { 0: "mr***5", 1: "", 2: "Book of Dead", 3: "134,055" },
      { 0: "the***31", 1: "", 2: "Mystery Joker", 3: "28,854" },
      { 0: "pp***986", 1: "", 2: "Golden Ticket 2", 3: "10,501" },
      { 0: "tnmi***51", 1: "", 2: "Temple of Wealth", 3: "124,251" },
      { 0: "love***64", 1: "", 2: "Celebration of Wealth", 3: "47,452" },
      { 0: "advc***h", 1: "", 2: "Fire Joker", 3: "108,521" },
      { 0: "pol***4", 1: "", 2: "GEMiX", 3: "45,254" },
      { 0: "zonr***n1", 1: "", 2: "Energoonz", 3: "404,272" },
      { 0: "opl***24", 1: "", 2: "Big Win Cat", 3: "148,247" },
      { 0: "ton***68", 1: "", 2: "Xmas Joker", 3: "14,529" },
      { 0: "bo***23722", 1: "", 2: "Goblin Treasure", 3: "10,560" },
      { 0: "jo***230", 1: "", 2: "Dirt Bike", 3: "15,811" },
      { 0: "ye***30", 1: "", 2: "Dog Racing", 3: "10,250" },
      { 0: "nn***42", 1: "", 2: "asure", 3: "10,250" },
      { 0: "kk09***5183", 1: "", 2: "Goblin Treasure", 3: "50,011" },
      { 0: "ps***4", 1: "", 2: "Goblin Treasure", 3: "17,050" },
      { 0: "n***08", 1: "", 2: "Goblin Treasure", 3: "12,524" },
      { 0: "kak***01", 1: "", 2: "Goblin Treasure", 3: "134,055" },
      { 0: "a***8046", 1: "", 2: "asure", 3: "12,025" },
      { 0: "ra***at", 1: "", 2: "Magic Journey", 3: "50,426" },
      { 0: "nic***993", 1: "", 2: "Dog Racing", 3: "31,005" },
      { 0: "ven***90", 1: "", 2: "PinBall Fortune", 3: "81,205" },
      { 0: "tant***21", 1: "", 2: "Dragon Clan", 3: "25,416" },
      { 0: "van***eree", 1: "", 2: "Dragon Gold SA", 3: "17,050" },
      { 0: "r***57", 1: "", 2: "Great Stars SA", 3: "41,857" },
      { 0: "sug***987", 1: "", 2: "Iceland SA", 3: "36,535" },
      { 0: "tayj***20", 1: "", 2: "Lucky Feng Shui", 3: "75,025" },
      { 0: "q1w***4t5", 1: "", 2: "Wong Choy SA", 3: "12,245" },
      { 0: "glum***980", 1: "", 2: "5 Fortune SA", 3: "17,542" },
      { 0: "ta***9", 1: "", 2: "King Pharaoh", 3: "80,521" },
      { 0: "po***07", 1: "", 2: "Mermaid", 3: "14,597" },
      { 0: "teera***n2520", 1: "", 2: "Wong Po", 3: "95,479" },
      { 0: "bun***37", 1: "", 2: "Adventure Iceland", 3: "132,023" },
      { 0: "thi***43", 1: "", 2: "Lucky Baby", 3: "42,540" },
      { 0: "son***am87", 1: "", 2: "Great Stars SA", 3: "15,420" },
      { 0: "pat***11", 1: "", 2: "Lucky Strike", 3: "245,985" },
      { 0: "ro***ma", 1: "", 2: "Railway King", 3: "75,421" },
      { 0: "ya***y27", 1: "", 2: "Spin Stone", 3: "25,647" },
      { 0: "tu***35", 1: "", 2: "Wong Choy", 3: "345,887" },
      { 0: "ben***526", 1: "", 2: "5 Fortune", 3: "41,254" },
      { 0: "taw***0444", 1: "", 2: "Master Chef", 3: "102,495" },
      { 0: "ong***man", 1: "", 2: "Moon Princess", 3: "45,967" },
      { 0: "par***96", 1: "", 2: "Rise of Olympus", 3: "65,624" },
      { 0: "ve***290", 1: "", 2: "Tome of Madness", 3: "17,050" },
      { 0: "ar***80", 1: "", 2: "Golden Ticket", 3: "15,665" },
      { 0: "ban***57", 1: "", 2: "Reactoonz", 3: "34,457" },
      { 0: "m9***82", 1: "", 2: "BlackJack MH", 3: "12,235" },
      { 0: "tw***ve", 1: "", 2: "Reactoonz 2", 3: "17,954" },
      { 0: "fdal***97", 1: "", 2: "Sweet Alchemy", 3: "164,157" },
      { 0: "you***6", 1: "", 2: "Honey Rush", 3: "94,636" }
    ],
	    usdt:[
	      { 0: "go***8", 1: "", 2: "ZEUS", 3: "11,052" },
	      { 0: "al***23", 1: "", 2: "Brothers kingdom", 3: "54,415" },
	      { 0: "bo***990", 1: "", 2: "5 Fortune Dragons", 3: "101,141" },
	      { 0: "b***i20", 1: "", 2: "Golden Monkey", 3: "13,045" },
	      { 0: "ons***789", 1: "", 2: "Fafafa", 3: "14,025" },
	      { 0: "u7u***2549", 1: "", 2: "Joker's Treasure", 3: "45,325" },
	      { 0: "p***27", 1: "", 2: "Cai Shen 888", 3: "14,025" },
	      { 0: "s***hui", 1: "", 2: "Money Mouse", 3: "10,560" },
	      { 0: "kk***1995", 1: "", 2: "FaFaFa2", 3: "18,235" },
	      { 0: "naru***2534", 1: "", 2: "Candy Pop", 3: "50,426" },
	      { 0: "kan***asin", 1: "", 2: "Ho Yeah Monkey", 3: "12,999" },
	      { 0: "m***th", 1: "", 2: "Double Flame", 3: "18,745" },
	      { 0: "sat***ath", 1: "", 2: "Book of Myth", 3: "16,824" },
	      { 0: "jne***11", 1: "", 2: "First Love", 3: "17,050" },
	      { 0: "lerd***n11", 1: "", 2: "Dancing Fever", 3: "10,520" },
	      { 0: "z***uhai", 1: "", 2: "Lucky Koi,", 3: "60,110" },
	      { 0: "sulk***law", 1: "", 2: "Mega 7", 3: "16,524" },
	      { 0: "par***1896", 1: "", 2: "Lucky Cai Shen", 3: "12,245" },
	      { 0: "aw***528", 1: "", 2: "Heroes", 3: "17,542" },
	      { 0: "kk***70", 1: "", 2: "Baby Cai Shen", 3: "17,050" },
	      { 0: "do***290", 1: "", 2: "Golden Lotus SE", 3: "14,597" },
	      { 0: "ba***48", 1: "", 2: "First Love", 3: "15,020" },
	      { 0: "jelen***539", 1: "", 2: "Mayan Gems", 3: "15,407" },
	      { 0: "krit***jkl40", 1: "", 2: "Princess Wang", 3: "16,547" },
	      { 0: "an***a", 1: "", 2: "Pineapple Poker", 3: "14,050" },
	      { 0: "lol***8", 1: "", 2: "Fiery Sevens", 3: "13,045" },
	      { 0: "ch***at", 1: "", 2: "Dragon Empire", 3: "201,125" },
	      { 0: "ble***82128", 1: "", 2: "Three Lucky Stars", 3: "24,251" },
	      { 0: "so***30", 1: "", 2: "Sweet Bakery", 3: "131,040" },
	      { 0: "a***ong", 1: "", 2: "Magical Lamp", 3: "145,971" },
	      { 0: "kav****25", 1: "", 2: "Gold Panther", 3: "17,050" },
	      { 0: "kl***ey55", 1: "", 2: "Triple Panda", 3: "11,052" },
	      { 0: "ae***ed", 1: "", 2: "Mr Chu Tycoon", 3: "11,052" },
	      { 0: "s***sit", 1: "", 2: "Jungle King", 3: "42,050" },
	      { 0: "bo***k25", 1: "", 2: "168 Fortunes", 3: "69,999" },
	      { 0: "ye***30", 1: "", 2: "Wow Prosperity", 3: "10,560" },
	      { 0: "ons***d789", 1: "", 2: "Golden Chicken", 3: "12,235" },
	      { 0: "ka9***gnid", 1: "", 2: "Gangster Axe", 3: "17,050" },
	      { 0: "suti***2535", 1: "", 2: "Cai Yuan Guang Jin", 3: "10,560" },
	      { 0: "chi***wat", 1: "", 2: "Da Fu Xiao Fu", 3: "15,210" },
	      { 0: "de***65", 1: "", 2: "Pan Fairy", 3: "41,254" },
	      { 0: "jakkra***0093", 1: "", 2: "Highway Fortune", 3: "114,104" },
	      { 0: "aej***99", 1: "", 2: "Big Prosperity SA", 3: "17,050" },
	      { 0: "ladd***oden", 1: "", 2: "chompoo", 3: "17,050" },
	      { 0: "toey***rvive", 1: "", 2: "Tiger Warrior", 3: "47,452" },
	      { 0: "xx***16", 1: "", 2: "Double Fortunes", 3: "10,502" },
	      { 0: "ch***26", 1: "", 2: "Golden Whale", 3: "10,105" },
	      { 0: "lad***den", 1: "", 2: "Alibaba", 3: "16,652" },
	      { 0: "zx***55", 1: "", 2: "Fist of Gold", 3: "17,050" },
	      { 0: "bu***i20", 1: "", 2: "Emperor Gate SA", 3: "11,052" },
	      { 0: "mr***5", 1: "", 2: "Book of Dead", 3: "134,055" },
	      { 0: "the***31", 1: "", 2: "Mystery Joker", 3: "28,854" },
	      { 0: "pp***986", 1: "", 2: "Golden Ticket 2", 3: "10,501" },
	      { 0: "tnmi***51", 1: "", 2: "Temple of Wealth", 3: "124,251" },
	      { 0: "love***64", 1: "", 2: "Celebration of Wealth", 3: "47,452" },
	      { 0: "advc***h", 1: "", 2: "Fire Joker", 3: "108,521" },
	      { 0: "pol***4", 1: "", 2: "GEMiX", 3: "45,254" },
	      { 0: "zonr***n1", 1: "", 2: "Energoonz", 3: "404,272" },
	      { 0: "opl***24", 1: "", 2: "Big Win Cat", 3: "148,247" },
	      { 0: "ton***68", 1: "", 2: "Xmas Joker", 3: "14,529" },
	      { 0: "bo***23722", 1: "", 2: "Goblin Treasure", 3: "10,560" },
	      { 0: "jo***230", 1: "", 2: "Dirt Bike", 3: "15,811" },
	      { 0: "ye***30", 1: "", 2: "Dog Racing", 3: "10,250" },
	      { 0: "nn***42", 1: "", 2: "asure", 3: "10,250" },
	      { 0: "kk09***5183", 1: "", 2: "Goblin Treasure", 3: "50,011" },
	      { 0: "ps***4", 1: "", 2: "Goblin Treasure", 3: "17,050" },
	      { 0: "n***08", 1: "", 2: "Goblin Treasure", 3: "12,524" },
	      { 0: "kak***01", 1: "", 2: "Goblin Treasure", 3: "134,055" },
	      { 0: "a***8046", 1: "", 2: "asure", 3: "12,025" },
	      { 0: "ra***at", 1: "", 2: "Magic Journey", 3: "50,426" },
	      { 0: "nic***993", 1: "", 2: "Dog Racing", 3: "31,005" },
	      { 0: "ven***90", 1: "", 2: "PinBall Fortune", 3: "81,205" },
	      { 0: "tant***21", 1: "", 2: "Dragon Clan", 3: "25,416" },
	      { 0: "van***eree", 1: "", 2: "Dragon Gold SA", 3: "17,050" },
	      { 0: "r***57", 1: "", 2: "Great Stars SA", 3: "41,857" },
	      { 0: "sug***987", 1: "", 2: "Iceland SA", 3: "36,535" },
	      { 0: "tayj***20", 1: "", 2: "Lucky Feng Shui", 3: "75,025" },
	      { 0: "q1w***4t5", 1: "", 2: "Wong Choy SA", 3: "12,245" },
	      { 0: "glum***980", 1: "", 2: "5 Fortune SA", 3: "17,542" },
	      { 0: "ta***9", 1: "", 2: "King Pharaoh", 3: "80,521" },
	      { 0: "po***07", 1: "", 2: "Mermaid", 3: "14,597" },
	      { 0: "teera***n2520", 1: "", 2: "Wong Po", 3: "95,479" },
	      { 0: "bun***37", 1: "", 2: "Adventure Iceland", 3: "132,023" },
	      { 0: "thi***43", 1: "", 2: "Lucky Baby", 3: "42,540" },
	      { 0: "son***am87", 1: "", 2: "Great Stars SA", 3: "15,420" },
	      { 0: "pat***11", 1: "", 2: "Lucky Strike", 3: "245,985" },
	      { 0: "ro***ma", 1: "", 2: "Railway King", 3: "75,421" },
	      { 0: "ya***y27", 1: "", 2: "Spin Stone", 3: "25,647" },
	      { 0: "tu***35", 1: "", 2: "Wong Choy", 3: "345,887" },
	      { 0: "ben***526", 1: "", 2: "5 Fortune", 3: "41,254" },
	      { 0: "taw***0444", 1: "", 2: "Master Chef", 3: "102,495" },
	      { 0: "ong***man", 1: "", 2: "Moon Princess", 3: "45,967" },
	      { 0: "par***96", 1: "", 2: "Rise of Olympus", 3: "65,624" },
	      { 0: "ve***290", 1: "", 2: "Tome of Madness", 3: "17,050" },
	      { 0: "ar***80", 1: "", 2: "Golden Ticket", 3: "15,665" },
	      { 0: "ban***57", 1: "", 2: "Reactoonz", 3: "34,457" },
	      { 0: "m9***82", 1: "", 2: "BlackJack MH", 3: "12,235" },
	      { 0: "tw***ve", 1: "", 2: "Reactoonz 2", 3: "17,954" },
	      { 0: "fdal***97", 1: "", 2: "Sweet Alchemy", 3: "164,157" },
	      { 0: "you***6", 1: "", 2: "Honey Rush", 3: "94,636" }
	    ],
    zh:[
      { 0: "go***8", 1: "", 2: "ZEUS", 3: "11,052" },
      { 0: "al***23", 1: "", 2: "Brothers kingdom", 3: "54,415" },
      { 0: "bo***990", 1: "", 2: "5 Fortune Dragons", 3: "101,141" },
      { 0: "b***i20", 1: "", 2: "Golden Monkey", 3: "13,045" },
      { 0: "ons***789", 1: "", 2: "Fafafa", 3: "14,025" },
      { 0: "u7u***2549", 1: "", 2: "Joker's Treasure", 3: "45,325" },
      { 0: "p***27", 1: "", 2: "Cai Shen 888", 3: "14,025" },
      { 0: "s***hui", 1: "", 2: "Money Mouse", 3: "10,560" },
      { 0: "kk***1995", 1: "", 2: "FaFaFa2", 3: "18,235" },
      { 0: "naru***2534", 1: "", 2: "Candy Pop", 3: "50,426" },
      { 0: "kan***asin", 1: "", 2: "Ho Yeah Monkey", 3: "12,999" },
      { 0: "m***th", 1: "", 2: "Double Flame", 3: "18,745" },
      { 0: "sat***ath", 1: "", 2: "Book of Myth", 3: "16,824" },
      { 0: "jne***11", 1: "", 2: "First Love", 3: "17,050" },
      { 0: "lerd***n11", 1: "", 2: "Dancing Fever", 3: "10,520" },
      { 0: "z***uhai", 1: "", 2: "Lucky Koi,", 3: "60,110" },
      { 0: "sulk***law", 1: "", 2: "Mega 7", 3: "16,524" },
      { 0: "par***1896", 1: "", 2: "Lucky Cai Shen", 3: "12,245" },
      { 0: "aw***528", 1: "", 2: "Heroes", 3: "17,542" },
      { 0: "kk***70", 1: "", 2: "Baby Cai Shen", 3: "17,050" },
      { 0: "do***290", 1: "", 2: "Golden Lotus SE", 3: "14,597" },
      { 0: "ba***48", 1: "", 2: "First Love", 3: "15,020" },
      { 0: "jelen***539", 1: "", 2: "Mayan Gems", 3: "15,407" },
      { 0: "krit***jkl40", 1: "", 2: "Princess Wang", 3: "16,547" },
      { 0: "an***a", 1: "", 2: "Pineapple Poker", 3: "14,050" },
      { 0: "lol***8", 1: "", 2: "Fiery Sevens", 3: "13,045" },
      { 0: "ch***at", 1: "", 2: "Dragon Empire", 3: "201,125" },
      { 0: "ble***82128", 1: "", 2: "Three Lucky Stars", 3: "24,251" },
      { 0: "so***30", 1: "", 2: "Sweet Bakery", 3: "131,040" },
      { 0: "a***ong", 1: "", 2: "Magical Lamp", 3: "145,971" },
      { 0: "kav****25", 1: "", 2: "Gold Panther", 3: "17,050" },
      { 0: "kl***ey55", 1: "", 2: "Triple Panda", 3: "11,052" },
      { 0: "ae***ed", 1: "", 2: "Mr Chu Tycoon", 3: "11,052" },
      { 0: "s***sit", 1: "", 2: "Jungle King", 3: "42,050" },
      { 0: "bo***k25", 1: "", 2: "168 Fortunes", 3: "69,999" },
      { 0: "ye***30", 1: "", 2: "Wow Prosperity", 3: "10,560" },
      { 0: "ons***d789", 1: "", 2: "Golden Chicken", 3: "12,235" },
      { 0: "ka9***gnid", 1: "", 2: "Gangster Axe", 3: "17,050" },
      { 0: "suti***2535", 1: "", 2: "Cai Yuan Guang Jin", 3: "10,560" },
      { 0: "chi***wat", 1: "", 2: "Da Fu Xiao Fu", 3: "15,210" },
      { 0: "de***65", 1: "", 2: "Pan Fairy", 3: "41,254" },
      { 0: "jakkra***0093", 1: "", 2: "Highway Fortune", 3: "114,104" },
      { 0: "aej***99", 1: "", 2: "Big Prosperity SA", 3: "17,050" },
      { 0: "ladd***oden", 1: "", 2: "chompoo", 3: "17,050" },
      { 0: "toey***rvive", 1: "", 2: "Tiger Warrior", 3: "47,452" },
      { 0: "xx***16", 1: "", 2: "Double Fortunes", 3: "10,502" },
      { 0: "ch***26", 1: "", 2: "Golden Whale", 3: "10,105" },
      { 0: "lad***den", 1: "", 2: "Alibaba", 3: "16,652" },
      { 0: "zx***55", 1: "", 2: "Fist of Gold", 3: "17,050" },
      { 0: "bu***i20", 1: "", 2: "Emperor Gate SA", 3: "11,052" },
      { 0: "mr***5", 1: "", 2: "Book of Dead", 3: "134,055" },
      { 0: "the***31", 1: "", 2: "Mystery Joker", 3: "28,854" },
      { 0: "pp***986", 1: "", 2: "Golden Ticket 2", 3: "10,501" },
      { 0: "tnmi***51", 1: "", 2: "Temple of Wealth", 3: "124,251" },
      { 0: "love***64", 1: "", 2: "Celebration of Wealth", 3: "47,452" },
      { 0: "advc***h", 1: "", 2: "Fire Joker", 3: "108,521" },
      { 0: "pol***4", 1: "", 2: "GEMiX", 3: "45,254" },
      { 0: "zonr***n1", 1: "", 2: "Energoonz", 3: "404,272" },
      { 0: "opl***24", 1: "", 2: "Big Win Cat", 3: "148,247" },
      { 0: "ton***68", 1: "", 2: "Xmas Joker", 3: "14,529" },
      { 0: "bo***23722", 1: "", 2: "Goblin Treasure", 3: "10,560" },
      { 0: "jo***230", 1: "", 2: "Dirt Bike", 3: "15,811" },
      { 0: "ye***30", 1: "", 2: "Dog Racing", 3: "10,250" },
      { 0: "nn***42", 1: "", 2: "asure", 3: "10,250" },
      { 0: "kk09***5183", 1: "", 2: "Goblin Treasure", 3: "50,011" },
      { 0: "ps***4", 1: "", 2: "Goblin Treasure", 3: "17,050" },
      { 0: "n***08", 1: "", 2: "Goblin Treasure", 3: "12,524" },
      { 0: "kak***01", 1: "", 2: "Goblin Treasure", 3: "134,055" },
      { 0: "a***8046", 1: "", 2: "asure", 3: "12,025" },
      { 0: "ra***at", 1: "", 2: "Magic Journey", 3: "50,426" },
      { 0: "nic***993", 1: "", 2: "Dog Racing", 3: "31,005" },
      { 0: "ven***90", 1: "", 2: "PinBall Fortune", 3: "81,205" },
      { 0: "tant***21", 1: "", 2: "Dragon Clan", 3: "25,416" },
      { 0: "van***eree", 1: "", 2: "Dragon Gold SA", 3: "17,050" },
      { 0: "r***57", 1: "", 2: "Great Stars SA", 3: "41,857" },
      { 0: "sug***987", 1: "", 2: "Iceland SA", 3: "36,535" },
      { 0: "tayj***20", 1: "", 2: "Lucky Feng Shui", 3: "75,025" },
      { 0: "q1w***4t5", 1: "", 2: "Wong Choy SA", 3: "12,245" },
      { 0: "glum***980", 1: "", 2: "5 Fortune SA", 3: "17,542" },
      { 0: "ta***9", 1: "", 2: "King Pharaoh", 3: "80,521" },
      { 0: "po***07", 1: "", 2: "Mermaid", 3: "14,597" },
      { 0: "teera***n2520", 1: "", 2: "Wong Po", 3: "95,479" },
      { 0: "bun***37", 1: "", 2: "Adventure Iceland", 3: "132,023" },
      { 0: "thi***43", 1: "", 2: "Lucky Baby", 3: "42,540" },
      { 0: "son***am87", 1: "", 2: "Great Stars SA", 3: "15,420" },
      { 0: "pat***11", 1: "", 2: "Lucky Strike", 3: "245,985" },
      { 0: "ro***ma", 1: "", 2: "Railway King", 3: "75,421" },
      { 0: "ya***y27", 1: "", 2: "Spin Stone", 3: "25,647" },
      { 0: "tu***35", 1: "", 2: "Wong Choy", 3: "345,887" },
      { 0: "ben***526", 1: "", 2: "5 Fortune", 3: "41,254" },
      { 0: "taw***0444", 1: "", 2: "Master Chef", 3: "102,495" },
      { 0: "ong***man", 1: "", 2: "Moon Princess", 3: "45,967" },
      { 0: "par***96", 1: "", 2: "Rise of Olympus", 3: "65,624" },
      { 0: "ve***290", 1: "", 2: "Tome of Madness", 3: "17,050" },
      { 0: "ar***80", 1: "", 2: "Golden Ticket", 3: "15,665" },
      { 0: "ban***57", 1: "", 2: "Reactoonz", 3: "34,457" },
      { 0: "m9***82", 1: "", 2: "BlackJack MH", 3: "12,235" },
      { 0: "tw***ve", 1: "", 2: "Reactoonz 2", 3: "17,954" },
      { 0: "fdal***97", 1: "", 2: "Sweet Alchemy", 3: "164,157" },
      { 0: "you***6", 1: "", 2: "Honey Rush", 3: "94,636" }
    ],
    en:[
      { 0: "go***8", 1: "", 2: "ZEUS", 3: "11,052" },
      { 0: "al***23", 1: "", 2: "Brothers kingdom", 3: "54,415" },
      { 0: "bo***990", 1: "", 2: "5 Fortune Dragons", 3: "101,141" },
      { 0: "b***i20", 1: "", 2: "Golden Monkey", 3: "13,045" },
      { 0: "ons***789", 1: "", 2: "Fafafa", 3: "14,025" },
      { 0: "u7u***2549", 1: "", 2: "Joker's Treasure", 3: "45,325" },
      { 0: "p***27", 1: "", 2: "Cai Shen 888", 3: "14,025" },
      { 0: "s***hui", 1: "", 2: "Money Mouse", 3: "10,560" },
      { 0: "kk***1995", 1: "", 2: "FaFaFa2", 3: "18,235" },
      { 0: "naru***2534", 1: "", 2: "Candy Pop", 3: "50,426" },
      { 0: "kan***asin", 1: "", 2: "Ho Yeah Monkey", 3: "12,999" },
      { 0: "m***th", 1: "", 2: "Double Flame", 3: "18,745" },
      { 0: "sat***ath", 1: "", 2: "Book of Myth", 3: "16,824" },
      { 0: "jne***11", 1: "", 2: "First Love", 3: "17,050" },
      { 0: "lerd***n11", 1: "", 2: "Dancing Fever", 3: "10,520" },
      { 0: "z***uhai", 1: "", 2: "Lucky Koi,", 3: "60,110" },
      { 0: "sulk***law", 1: "", 2: "Mega 7", 3: "16,524" },
      { 0: "par***1896", 1: "", 2: "Lucky Cai Shen", 3: "12,245" },
      { 0: "aw***528", 1: "", 2: "Heroes", 3: "17,542" },
      { 0: "kk***70", 1: "", 2: "Baby Cai Shen", 3: "17,050" },
      { 0: "do***290", 1: "", 2: "Golden Lotus SE", 3: "14,597" },
      { 0: "ba***48", 1: "", 2: "First Love", 3: "15,020" },
      { 0: "jelen***539", 1: "", 2: "Mayan Gems", 3: "15,407" },
      { 0: "krit***jkl40", 1: "", 2: "Princess Wang", 3: "16,547" },
      { 0: "an***a", 1: "", 2: "Pineapple Poker", 3: "14,050" },
      { 0: "lol***8", 1: "", 2: "Fiery Sevens", 3: "13,045" },
      { 0: "ch***at", 1: "", 2: "Dragon Empire", 3: "201,125" },
      { 0: "ble***82128", 1: "", 2: "Three Lucky Stars", 3: "24,251" },
      { 0: "so***30", 1: "", 2: "Sweet Bakery", 3: "131,040" },
      { 0: "a***ong", 1: "", 2: "Magical Lamp", 3: "145,971" },
      { 0: "kav****25", 1: "", 2: "Gold Panther", 3: "17,050" },
      { 0: "kl***ey55", 1: "", 2: "Triple Panda", 3: "11,052" },
      { 0: "ae***ed", 1: "", 2: "Mr Chu Tycoon", 3: "11,052" },
      { 0: "s***sit", 1: "", 2: "Jungle King", 3: "42,050" },
      { 0: "bo***k25", 1: "", 2: "168 Fortunes", 3: "69,999" },
      { 0: "ye***30", 1: "", 2: "Wow Prosperity", 3: "10,560" },
      { 0: "ons***d789", 1: "", 2: "Golden Chicken", 3: "12,235" },
      { 0: "ka9***gnid", 1: "", 2: "Gangster Axe", 3: "17,050" },
      { 0: "suti***2535", 1: "", 2: "Cai Yuan Guang Jin", 3: "10,560" },
      { 0: "chi***wat", 1: "", 2: "Da Fu Xiao Fu", 3: "15,210" },
      { 0: "de***65", 1: "", 2: "Pan Fairy", 3: "41,254" },
      { 0: "jakkra***0093", 1: "", 2: "Highway Fortune", 3: "114,104" },
      { 0: "aej***99", 1: "", 2: "Big Prosperity SA", 3: "17,050" },
      { 0: "ladd***oden", 1: "", 2: "chompoo", 3: "17,050" },
      { 0: "toey***rvive", 1: "", 2: "Tiger Warrior", 3: "47,452" },
      { 0: "xx***16", 1: "", 2: "Double Fortunes", 3: "10,502" },
      { 0: "ch***26", 1: "", 2: "Golden Whale", 3: "10,105" },
      { 0: "lad***den", 1: "", 2: "Alibaba", 3: "16,652" },
      { 0: "zx***55", 1: "", 2: "Fist of Gold", 3: "17,050" },
      { 0: "bu***i20", 1: "", 2: "Emperor Gate SA", 3: "11,052" },
      { 0: "mr***5", 1: "", 2: "Book of Dead", 3: "134,055" },
      { 0: "the***31", 1: "", 2: "Mystery Joker", 3: "28,854" },
      { 0: "pp***986", 1: "", 2: "Golden Ticket 2", 3: "10,501" },
      { 0: "tnmi***51", 1: "", 2: "Temple of Wealth", 3: "124,251" },
      { 0: "love***64", 1: "", 2: "Celebration of Wealth", 3: "47,452" },
      { 0: "advc***h", 1: "", 2: "Fire Joker", 3: "108,521" },
      { 0: "pol***4", 1: "", 2: "GEMiX", 3: "45,254" },
      { 0: "zonr***n1", 1: "", 2: "Energoonz", 3: "404,272" },
      { 0: "opl***24", 1: "", 2: "Big Win Cat", 3: "148,247" },
      { 0: "ton***68", 1: "", 2: "Xmas Joker", 3: "14,529" },
      { 0: "bo***23722", 1: "", 2: "Goblin Treasure", 3: "10,560" },
      { 0: "jo***230", 1: "", 2: "Dirt Bike", 3: "15,811" },
      { 0: "ye***30", 1: "", 2: "Dog Racing", 3: "10,250" },
      { 0: "nn***42", 1: "", 2: "asure", 3: "10,250" },
      { 0: "kk09***5183", 1: "", 2: "Goblin Treasure", 3: "50,011" },
      { 0: "ps***4", 1: "", 2: "Goblin Treasure", 3: "17,050" },
      { 0: "n***08", 1: "", 2: "Goblin Treasure", 3: "12,524" },
      { 0: "kak***01", 1: "", 2: "Goblin Treasure", 3: "134,055" },
      { 0: "a***8046", 1: "", 2: "asure", 3: "12,025" },
      { 0: "ra***at", 1: "", 2: "Magic Journey", 3: "50,426" },
      { 0: "nic***993", 1: "", 2: "Dog Racing", 3: "31,005" },
      { 0: "ven***90", 1: "", 2: "PinBall Fortune", 3: "81,205" },
      { 0: "tant***21", 1: "", 2: "Dragon Clan", 3: "25,416" },
      { 0: "van***eree", 1: "", 2: "Dragon Gold SA", 3: "17,050" },
      { 0: "r***57", 1: "", 2: "Great Stars SA", 3: "41,857" },
      { 0: "sug***987", 1: "", 2: "Iceland SA", 3: "36,535" },
      { 0: "tayj***20", 1: "", 2: "Lucky Feng Shui", 3: "75,025" },
      { 0: "q1w***4t5", 1: "", 2: "Wong Choy SA", 3: "12,245" },
      { 0: "glum***980", 1: "", 2: "5 Fortune SA", 3: "17,542" },
      { 0: "ta***9", 1: "", 2: "King Pharaoh", 3: "80,521" },
      { 0: "po***07", 1: "", 2: "Mermaid", 3: "14,597" },
      { 0: "teera***n2520", 1: "", 2: "Wong Po", 3: "95,479" },
      { 0: "bun***37", 1: "", 2: "Adventure Iceland", 3: "132,023" },
      { 0: "thi***43", 1: "", 2: "Lucky Baby", 3: "42,540" },
      { 0: "son***am87", 1: "", 2: "Great Stars SA", 3: "15,420" },
      { 0: "pat***11", 1: "", 2: "Lucky Strike", 3: "245,985" },
      { 0: "ro***ma", 1: "", 2: "Railway King", 3: "75,421" },
      { 0: "ya***y27", 1: "", 2: "Spin Stone", 3: "25,647" },
      { 0: "tu***35", 1: "", 2: "Wong Choy", 3: "345,887" },
      { 0: "ben***526", 1: "", 2: "5 Fortune", 3: "41,254" },
      { 0: "taw***0444", 1: "", 2: "Master Chef", 3: "102,495" },
      { 0: "ong***man", 1: "", 2: "Moon Princess", 3: "45,967" },
      { 0: "par***96", 1: "", 2: "Rise of Olympus", 3: "65,624" },
      { 0: "ve***290", 1: "", 2: "Tome of Madness", 3: "17,050" },
      { 0: "ar***80", 1: "", 2: "Golden Ticket", 3: "15,665" },
      { 0: "ban***57", 1: "", 2: "Reactoonz", 3: "34,457" },
      { 0: "m9***82", 1: "", 2: "BlackJack MH", 3: "12,235" },
      { 0: "tw***ve", 1: "", 2: "Reactoonz 2", 3: "17,954" },
      { 0: "fdal***97", 1: "", 2: "Sweet Alchemy", 3: "164,157" },
      { 0: "you***6", 1: "", 2: "Honey Rush", 3: "94,636" }
    ],
    yn:[
      { 0: "go***8", 1: "", 2: "ZEUS", 3: "11,052" },
      { 0: "al***23", 1: "", 2: "Brothers kingdom", 3: "54,415" },
      { 0: "bo***990", 1: "", 2: "5 Fortune Dragons", 3: "101,141" },
      { 0: "b***i20", 1: "", 2: "Golden Monkey", 3: "13,045" },
      { 0: "ons***789", 1: "", 2: "Fafafa", 3: "14,025" },
      { 0: "u7u***2549", 1: "", 2: "Joker's Treasure", 3: "45,325" },
      { 0: "p***27", 1: "", 2: "Cai Shen 888", 3: "14,025" },
      { 0: "s***hui", 1: "", 2: "Money Mouse", 3: "10,560" },
      { 0: "kk***1995", 1: "", 2: "FaFaFa2", 3: "18,235" },
      { 0: "naru***2534", 1: "", 2: "Candy Pop", 3: "50,426" },
      { 0: "kan***asin", 1: "", 2: "Ho Yeah Monkey", 3: "12,999" },
      { 0: "m***th", 1: "", 2: "Double Flame", 3: "18,745" },
      { 0: "sat***ath", 1: "", 2: "Book of Myth", 3: "16,824" },
      { 0: "jne***11", 1: "", 2: "First Love", 3: "17,050" },
      { 0: "lerd***n11", 1: "", 2: "Dancing Fever", 3: "10,520" },
      { 0: "z***uhai", 1: "", 2: "Lucky Koi,", 3: "60,110" },
      { 0: "sulk***law", 1: "", 2: "Mega 7", 3: "16,524" },
      { 0: "par***1896", 1: "", 2: "Lucky Cai Shen", 3: "12,245" },
      { 0: "aw***528", 1: "", 2: "Heroes", 3: "17,542" },
      { 0: "kk***70", 1: "", 2: "Baby Cai Shen", 3: "17,050" },
      { 0: "do***290", 1: "", 2: "Golden Lotus SE", 3: "14,597" },
      { 0: "ba***48", 1: "", 2: "First Love", 3: "15,020" },
      { 0: "jelen***539", 1: "", 2: "Mayan Gems", 3: "15,407" },
      { 0: "krit***jkl40", 1: "", 2: "Princess Wang", 3: "16,547" },
      { 0: "an***a", 1: "", 2: "Pineapple Poker", 3: "14,050" },
      { 0: "lol***8", 1: "", 2: "Fiery Sevens", 3: "13,045" },
      { 0: "ch***at", 1: "", 2: "Dragon Empire", 3: "201,125" },
      { 0: "ble***82128", 1: "", 2: "Three Lucky Stars", 3: "24,251" },
      { 0: "so***30", 1: "", 2: "Sweet Bakery", 3: "131,040" },
      { 0: "a***ong", 1: "", 2: "Magical Lamp", 3: "145,971" },
      { 0: "kav****25", 1: "", 2: "Gold Panther", 3: "17,050" },
      { 0: "kl***ey55", 1: "", 2: "Triple Panda", 3: "11,052" },
      { 0: "ae***ed", 1: "", 2: "Mr Chu Tycoon", 3: "11,052" },
      { 0: "s***sit", 1: "", 2: "Jungle King", 3: "42,050" },
      { 0: "bo***k25", 1: "", 2: "168 Fortunes", 3: "69,999" },
      { 0: "ye***30", 1: "", 2: "Wow Prosperity", 3: "10,560" },
      { 0: "ons***d789", 1: "", 2: "Golden Chicken", 3: "12,235" },
      { 0: "ka9***gnid", 1: "", 2: "Gangster Axe", 3: "17,050" },
      { 0: "suti***2535", 1: "", 2: "Cai Yuan Guang Jin", 3: "10,560" },
      { 0: "chi***wat", 1: "", 2: "Da Fu Xiao Fu", 3: "15,210" },
      { 0: "de***65", 1: "", 2: "Pan Fairy", 3: "41,254" },
      { 0: "jakkra***0093", 1: "", 2: "Highway Fortune", 3: "114,104" },
      { 0: "aej***99", 1: "", 2: "Big Prosperity SA", 3: "17,050" },
      { 0: "ladd***oden", 1: "", 2: "chompoo", 3: "17,050" },
      { 0: "toey***rvive", 1: "", 2: "Tiger Warrior", 3: "47,452" },
      { 0: "xx***16", 1: "", 2: "Double Fortunes", 3: "10,502" },
      { 0: "ch***26", 1: "", 2: "Golden Whale", 3: "10,105" },
      { 0: "lad***den", 1: "", 2: "Alibaba", 3: "16,652" },
      { 0: "zx***55", 1: "", 2: "Fist of Gold", 3: "17,050" },
      { 0: "bu***i20", 1: "", 2: "Emperor Gate SA", 3: "11,052" },
      { 0: "mr***5", 1: "", 2: "Book of Dead", 3: "134,055" },
      { 0: "the***31", 1: "", 2: "Mystery Joker", 3: "28,854" },
      { 0: "pp***986", 1: "", 2: "Golden Ticket 2", 3: "10,501" },
      { 0: "tnmi***51", 1: "", 2: "Temple of Wealth", 3: "124,251" },
      { 0: "love***64", 1: "", 2: "Celebration of Wealth", 3: "47,452" },
      { 0: "advc***h", 1: "", 2: "Fire Joker", 3: "108,521" },
      { 0: "pol***4", 1: "", 2: "GEMiX", 3: "45,254" },
      { 0: "zonr***n1", 1: "", 2: "Energoonz", 3: "404,272" },
      { 0: "opl***24", 1: "", 2: "Big Win Cat", 3: "148,247" },
      { 0: "ton***68", 1: "", 2: "Xmas Joker", 3: "14,529" },
      { 0: "bo***23722", 1: "", 2: "Goblin Treasure", 3: "10,560" },
      { 0: "jo***230", 1: "", 2: "Dirt Bike", 3: "15,811" },
      { 0: "ye***30", 1: "", 2: "Dog Racing", 3: "10,250" },
      { 0: "nn***42", 1: "", 2: "asure", 3: "10,250" },
      { 0: "kk09***5183", 1: "", 2: "Goblin Treasure", 3: "50,011" },
      { 0: "ps***4", 1: "", 2: "Goblin Treasure", 3: "17,050" },
      { 0: "n***08", 1: "", 2: "Goblin Treasure", 3: "12,524" },
      { 0: "kak***01", 1: "", 2: "Goblin Treasure", 3: "134,055" },
      { 0: "a***8046", 1: "", 2: "asure", 3: "12,025" },
      { 0: "ra***at", 1: "", 2: "Magic Journey", 3: "50,426" },
      { 0: "nic***993", 1: "", 2: "Dog Racing", 3: "31,005" },
      { 0: "ven***90", 1: "", 2: "PinBall Fortune", 3: "81,205" },
      { 0: "tant***21", 1: "", 2: "Dragon Clan", 3: "25,416" },
      { 0: "van***eree", 1: "", 2: "Dragon Gold SA", 3: "17,050" },
      { 0: "r***57", 1: "", 2: "Great Stars SA", 3: "41,857" },
      { 0: "sug***987", 1: "", 2: "Iceland SA", 3: "36,535" },
      { 0: "tayj***20", 1: "", 2: "Lucky Feng Shui", 3: "75,025" },
      { 0: "q1w***4t5", 1: "", 2: "Wong Choy SA", 3: "12,245" },
      { 0: "glum***980", 1: "", 2: "5 Fortune SA", 3: "17,542" },
      { 0: "ta***9", 1: "", 2: "King Pharaoh", 3: "80,521" },
      { 0: "po***07", 1: "", 2: "Mermaid", 3: "14,597" },
      { 0: "teera***n2520", 1: "", 2: "Wong Po", 3: "95,479" },
      { 0: "bun***37", 1: "", 2: "Adventure Iceland", 3: "132,023" },
      { 0: "thi***43", 1: "", 2: "Lucky Baby", 3: "42,540" },
      { 0: "son***am87", 1: "", 2: "Great Stars SA", 3: "15,420" },
      { 0: "pat***11", 1: "", 2: "Lucky Strike", 3: "245,985" },
      { 0: "ro***ma", 1: "", 2: "Railway King", 3: "75,421" },
      { 0: "ya***y27", 1: "", 2: "Spin Stone", 3: "25,647" },
      { 0: "tu***35", 1: "", 2: "Wong Choy", 3: "345,887" },
      { 0: "ben***526", 1: "", 2: "5 Fortune", 3: "41,254" },
      { 0: "taw***0444", 1: "", 2: "Master Chef", 3: "102,495" },
      { 0: "ong***man", 1: "", 2: "Moon Princess", 3: "45,967" },
      { 0: "par***96", 1: "", 2: "Rise of Olympus", 3: "65,624" },
      { 0: "ve***290", 1: "", 2: "Tome of Madness", 3: "17,050" },
      { 0: "ar***80", 1: "", 2: "Golden Ticket", 3: "15,665" },
      { 0: "ban***57", 1: "", 2: "Reactoonz", 3: "34,457" },
      { 0: "m9***82", 1: "", 2: "BlackJack MH", 3: "12,235" },
      { 0: "tw***ve", 1: "", 2: "Reactoonz 2", 3: "17,954" },
      { 0: "fdal***97", 1: "", 2: "Sweet Alchemy", 3: "164,157" },
      { 0: "you***6", 1: "", 2: "Honey Rush", 3: "94,636" }
    ],
    id:[
      { 0: "go***8", 1: "", 2: "ZEUS", 3: "11,052" },
      { 0: "al***23", 1: "", 2: "Brothers kingdom", 3: "54,415" },
      { 0: "bo***990", 1: "", 2: "5 Fortune Dragons", 3: "101,141" },
      { 0: "b***i20", 1: "", 2: "Golden Monkey", 3: "13,045" },
      { 0: "ons***789", 1: "", 2: "Fafafa", 3: "14,025" },
      { 0: "u7u***2549", 1: "", 2: "Joker's Treasure", 3: "45,325" },
      { 0: "p***27", 1: "", 2: "Cai Shen 888", 3: "14,025" },
      { 0: "s***hui", 1: "", 2: "Money Mouse", 3: "10,560" },
      { 0: "kk***1995", 1: "", 2: "FaFaFa2", 3: "18,235" },
      { 0: "naru***2534", 1: "", 2: "Candy Pop", 3: "50,426" },
      { 0: "kan***asin", 1: "", 2: "Ho Yeah Monkey", 3: "12,999" },
      { 0: "m***th", 1: "", 2: "Double Flame", 3: "18,745" },
      { 0: "sat***ath", 1: "", 2: "Book of Myth", 3: "16,824" },
      { 0: "jne***11", 1: "", 2: "First Love", 3: "17,050" },
      { 0: "lerd***n11", 1: "", 2: "Dancing Fever", 3: "10,520" },
      { 0: "z***uhai", 1: "", 2: "Lucky Koi,", 3: "60,110" },
      { 0: "sulk***law", 1: "", 2: "Mega 7", 3: "16,524" },
      { 0: "par***1896", 1: "", 2: "Lucky Cai Shen", 3: "12,245" },
      { 0: "aw***528", 1: "", 2: "Heroes", 3: "17,542" },
      { 0: "kk***70", 1: "", 2: "Baby Cai Shen", 3: "17,050" },
      { 0: "do***290", 1: "", 2: "Golden Lotus SE", 3: "14,597" },
      { 0: "ba***48", 1: "", 2: "First Love", 3: "15,020" },
      { 0: "jelen***539", 1: "", 2: "Mayan Gems", 3: "15,407" },
      { 0: "krit***jkl40", 1: "", 2: "Princess Wang", 3: "16,547" },
      { 0: "an***a", 1: "", 2: "Pineapple Poker", 3: "14,050" },
      { 0: "lol***8", 1: "", 2: "Fiery Sevens", 3: "13,045" },
      { 0: "ch***at", 1: "", 2: "Dragon Empire", 3: "201,125" },
      { 0: "ble***82128", 1: "", 2: "Three Lucky Stars", 3: "24,251" },
      { 0: "so***30", 1: "", 2: "Sweet Bakery", 3: "131,040" },
      { 0: "a***ong", 1: "", 2: "Magical Lamp", 3: "145,971" },
      { 0: "kav****25", 1: "", 2: "Gold Panther", 3: "17,050" },
      { 0: "kl***ey55", 1: "", 2: "Triple Panda", 3: "11,052" },
      { 0: "ae***ed", 1: "", 2: "Mr Chu Tycoon", 3: "11,052" },
      { 0: "s***sit", 1: "", 2: "Jungle King", 3: "42,050" },
      { 0: "bo***k25", 1: "", 2: "168 Fortunes", 3: "69,999" },
      { 0: "ye***30", 1: "", 2: "Wow Prosperity", 3: "10,560" },
      { 0: "ons***d789", 1: "", 2: "Golden Chicken", 3: "12,235" },
      { 0: "ka9***gnid", 1: "", 2: "Gangster Axe", 3: "17,050" },
      { 0: "suti***2535", 1: "", 2: "Cai Yuan Guang Jin", 3: "10,560" },
      { 0: "chi***wat", 1: "", 2: "Da Fu Xiao Fu", 3: "15,210" },
      { 0: "de***65", 1: "", 2: "Pan Fairy", 3: "41,254" },
      { 0: "jakkra***0093", 1: "", 2: "Highway Fortune", 3: "114,104" },
      { 0: "aej***99", 1: "", 2: "Big Prosperity SA", 3: "17,050" },
      { 0: "ladd***oden", 1: "", 2: "chompoo", 3: "17,050" },
      { 0: "toey***rvive", 1: "", 2: "Tiger Warrior", 3: "47,452" },
      { 0: "xx***16", 1: "", 2: "Double Fortunes", 3: "10,502" },
      { 0: "ch***26", 1: "", 2: "Golden Whale", 3: "10,105" },
      { 0: "lad***den", 1: "", 2: "Alibaba", 3: "16,652" },
      { 0: "zx***55", 1: "", 2: "Fist of Gold", 3: "17,050" },
      { 0: "bu***i20", 1: "", 2: "Emperor Gate SA", 3: "11,052" },
      { 0: "mr***5", 1: "", 2: "Book of Dead", 3: "134,055" },
      { 0: "the***31", 1: "", 2: "Mystery Joker", 3: "28,854" },
      { 0: "pp***986", 1: "", 2: "Golden Ticket 2", 3: "10,501" },
      { 0: "tnmi***51", 1: "", 2: "Temple of Wealth", 3: "124,251" },
      { 0: "love***64", 1: "", 2: "Celebration of Wealth", 3: "47,452" },
      { 0: "advc***h", 1: "", 2: "Fire Joker", 3: "108,521" },
      { 0: "pol***4", 1: "", 2: "GEMiX", 3: "45,254" },
      { 0: "zonr***n1", 1: "", 2: "Energoonz", 3: "404,272" },
      { 0: "opl***24", 1: "", 2: "Big Win Cat", 3: "148,247" },
      { 0: "ton***68", 1: "", 2: "Xmas Joker", 3: "14,529" },
      { 0: "bo***23722", 1: "", 2: "Goblin Treasure", 3: "10,560" },
      { 0: "jo***230", 1: "", 2: "Dirt Bike", 3: "15,811" },
      { 0: "ye***30", 1: "", 2: "Dog Racing", 3: "10,250" },
      { 0: "nn***42", 1: "", 2: "asure", 3: "10,250" },
      { 0: "kk09***5183", 1: "", 2: "Goblin Treasure", 3: "50,011" },
      { 0: "ps***4", 1: "", 2: "Goblin Treasure", 3: "17,050" },
      { 0: "n***08", 1: "", 2: "Goblin Treasure", 3: "12,524" },
      { 0: "kak***01", 1: "", 2: "Goblin Treasure", 3: "134,055" },
      { 0: "a***8046", 1: "", 2: "asure", 3: "12,025" },
      { 0: "ra***at", 1: "", 2: "Magic Journey", 3: "50,426" },
      { 0: "nic***993", 1: "", 2: "Dog Racing", 3: "31,005" },
      { 0: "ven***90", 1: "", 2: "PinBall Fortune", 3: "81,205" },
      { 0: "tant***21", 1: "", 2: "Dragon Clan", 3: "25,416" },
      { 0: "van***eree", 1: "", 2: "Dragon Gold SA", 3: "17,050" },
      { 0: "r***57", 1: "", 2: "Great Stars SA", 3: "41,857" },
      { 0: "sug***987", 1: "", 2: "Iceland SA", 3: "36,535" },
      { 0: "tayj***20", 1: "", 2: "Lucky Feng Shui", 3: "75,025" },
      { 0: "q1w***4t5", 1: "", 2: "Wong Choy SA", 3: "12,245" },
      { 0: "glum***980", 1: "", 2: "5 Fortune SA", 3: "17,542" },
      { 0: "ta***9", 1: "", 2: "King Pharaoh", 3: "80,521" },
      { 0: "po***07", 1: "", 2: "Mermaid", 3: "14,597" },
      { 0: "teera***n2520", 1: "", 2: "Wong Po", 3: "95,479" },
      { 0: "bun***37", 1: "", 2: "Adventure Iceland", 3: "132,023" },
      { 0: "thi***43", 1: "", 2: "Lucky Baby", 3: "42,540" },
      { 0: "son***am87", 1: "", 2: "Great Stars SA", 3: "15,420" },
      { 0: "pat***11", 1: "", 2: "Lucky Strike", 3: "245,985" },
      { 0: "ro***ma", 1: "", 2: "Railway King", 3: "75,421" },
      { 0: "ya***y27", 1: "", 2: "Spin Stone", 3: "25,647" },
      { 0: "tu***35", 1: "", 2: "Wong Choy", 3: "345,887" },
      { 0: "ben***526", 1: "", 2: "5 Fortune", 3: "41,254" },
      { 0: "taw***0444", 1: "", 2: "Master Chef", 3: "102,495" },
      { 0: "ong***man", 1: "", 2: "Moon Princess", 3: "45,967" },
      { 0: "par***96", 1: "", 2: "Rise of Olympus", 3: "65,624" },
      { 0: "ve***290", 1: "", 2: "Tome of Madness", 3: "17,050" },
      { 0: "ar***80", 1: "", 2: "Golden Ticket", 3: "15,665" },
      { 0: "ban***57", 1: "", 2: "Reactoonz", 3: "34,457" },
      { 0: "m9***82", 1: "", 2: "BlackJack MH", 3: "12,235" },
      { 0: "tw***ve", 1: "", 2: "Reactoonz 2", 3: "17,954" },
      { 0: "fdal***97", 1: "", 2: "Sweet Alchemy", 3: "164,157" },
      { 0: "you***6", 1: "", 2: "Honey Rush", 3: "94,636" }
    ],

}

export const menberDatas = {
  th:[
      { 0: "m**ke", 1: "", 2: "โบนัสอัดเกรค", 3: "10,238" },
      { 0: "luc****yy", 1: "", 2: "โบนัสอัดเกรค", 3: "23,888" },
      { 0: "jne***233", 1: "", 2: "โบนัสนำโชค", 3: "203,188" },
      { 0: "no***rio", 1: "", 2: "โบนัสนำโชค", 3: "13,158" },
      { 0: "pa***mee", 1: "", 2: "โบนัสนำโชค", 3: "123,132" },
      { 0: "cre***309", 1: "", 2: "โบนัสนำโชค", 3: "14,258" },
      { 0: "me***k89", 1: "", 2: "โบนัสนำโชค", 3: "27,288" },
      { 0: "dun****t27", 1: "", 2: "โบนัสวันเกิด", 3: "95,120" },
      { 0: "ma**le", 1: "", 2: "โบนัสวันเกิด", 3: "95,120" },
      { 0: "xia***ng", 1: "", 2: "โบนัสวันเกิด", 3: "145,060" },
      { 0: "apil***527", 1: "", 2: "โบนัสวันเกิด", 3: "94,130" },
      { 0: "zx***55", 1: "", 2: "โบนัสอัดเกรค", 3: "11,188" },
      { 0: "al***23", 1: "", 2: "โบนัสวันเกิด", 3: "18,400" },
      { 0: "naru***2534", 1: "", 2: "โบนัสอัดเกรค", 3: "32,588" },
      { 0: "toy***99", 1: "", 2: "โบนัสนำโชค", 3: "101,888" },
      { 0: "kan***asin", 1: "", 2: "โบนัสนำโชค", 3: "21,688" },
      { 0: "m***th", 1: "", 2: "โบนัสวันเกิด", 3: "81,560" },
      { 0: "par***1896", 1: "", 2: "โบนัสนำโชค", 3: "11,788" },
      { 0: "z***uhai", 1: "", 2: "โบนัสยอดเสีย", 3: "150,701" },
      { 0: "sat***ath", 1: "", 2: "โบนัสยอดเสีย", 3: "95,120" },
      { 0: "jne***11", 1: "", 2: "โบนัสอัดเกรค", 3: "203,188" },
      { 0: "sulk***law", 1: "", 2: "โบนัสวันเกิด", 3: "13,560" },
      { 0: "aw***528", 1: "", 2: "โบนัสยอดเสีย", 3: "39,874" },
      { 0: "kk***70", 1: "", 2: "โบนัสอัดเกรค", 3: "27,088" },
      { 0: "jelen***539", 1: "", 2: "โบนัสนำโชค", 3: "32,588" },
      { 0: "krit***jkl40", 1: "", 2: "โบนัสยอดเสีย", 3: "12,076" },
      { 0: "ba***48", 1: "", 2: "โบนัสนำโชค", 3: "18,458" },
      { 0: "do***290", 1: "", 2: "โบนัสอัดเกรค", 3: "27,088" },
    ],
	
	  usdt:[
	      { 0: "m**ke", 1: "", 2: "โบนัสอัดเกรค", 3: "10,238" },
	      { 0: "luc****yy", 1: "", 2: "โบนัสอัดเกรค", 3: "23,888" },
	      { 0: "jne***233", 1: "", 2: "โบนัสนำโชค", 3: "203,188" },
	      { 0: "no***rio", 1: "", 2: "โบนัสนำโชค", 3: "13,158" },
	      { 0: "pa***mee", 1: "", 2: "โบนัสนำโชค", 3: "123,132" },
	      { 0: "cre***309", 1: "", 2: "โบนัสนำโชค", 3: "14,258" },
	      { 0: "me***k89", 1: "", 2: "โบนัสนำโชค", 3: "27,288" },
	      { 0: "dun****t27", 1: "", 2: "โบนัสวันเกิด", 3: "95,120" },
	      { 0: "ma**le", 1: "", 2: "โบนัสวันเกิด", 3: "95,120" },
	      { 0: "xia***ng", 1: "", 2: "โบนัสวันเกิด", 3: "145,060" },
	      { 0: "apil***527", 1: "", 2: "โบนัสวันเกิด", 3: "94,130" },
	      { 0: "zx***55", 1: "", 2: "โบนัสอัดเกรค", 3: "11,188" },
	      { 0: "al***23", 1: "", 2: "โบนัสวันเกิด", 3: "18,400" },
	      { 0: "naru***2534", 1: "", 2: "โบนัสอัดเกรค", 3: "32,588" },
	      { 0: "toy***99", 1: "", 2: "โบนัสนำโชค", 3: "101,888" },
	      { 0: "kan***asin", 1: "", 2: "โบนัสนำโชค", 3: "21,688" },
	      { 0: "m***th", 1: "", 2: "โบนัสวันเกิด", 3: "81,560" },
	      { 0: "par***1896", 1: "", 2: "โบนัสนำโชค", 3: "11,788" },
	      { 0: "z***uhai", 1: "", 2: "โบนัสยอดเสีย", 3: "150,701" },
	      { 0: "sat***ath", 1: "", 2: "โบนัสยอดเสีย", 3: "95,120" },
	      { 0: "jne***11", 1: "", 2: "โบนัสอัดเกรค", 3: "203,188" },
	      { 0: "sulk***law", 1: "", 2: "โบนัสวันเกิด", 3: "13,560" },
	      { 0: "aw***528", 1: "", 2: "โบนัสยอดเสีย", 3: "39,874" },
	      { 0: "kk***70", 1: "", 2: "โบนัสอัดเกรค", 3: "27,088" },
	      { 0: "jelen***539", 1: "", 2: "โบนัสนำโชค", 3: "32,588" },
	      { 0: "krit***jkl40", 1: "", 2: "โบนัสยอดเสีย", 3: "12,076" },
	      { 0: "ba***48", 1: "", 2: "โบนัสนำโชค", 3: "18,458" },
	      { 0: "do***290", 1: "", 2: "โบนัสอัดเกรค", 3: "27,088" },
	    ],
    zh:[
      { 0: "m**ke", 1: "", 2: "晋级彩金", 3: "10,238" },
      { 0: "luc****yy", 1: "", 2: "晋级彩金", 3: "23,888" },
      { 0: "jne***233", 1: "", 2: "月月好运彩金", 3: "203,188" },
      { 0: "no***rio", 1: "", 2: "月月好运彩金", 3: "13,158" },
      { 0: "pa***mee", 1: "", 2: "月月好运彩金", 3: "123,132" },
      { 0: "cre***309", 1: "", 2: "月月好运彩金", 3: "14,258" },
      { 0: "me***k89", 1: "", 2: "月月好运彩金", 3: "27,288" },
      { 0: "kk***070", 1: "", 2: "节日礼金", 3: "34,140" },
      { 0: "dun****t27", 1: "", 2: "生日礼金", 3: "95,120" },
      { 0: "win***ken", 1: "", 2: "节日礼金", 3: "34,140" },
      { 0: "me***89", 1: "", 2: "节日礼金", 3: "34,140" },
      { 0: "ma**le", 1: "", 2: "生日礼金", 3: "95,120" },
      { 0: "xia***ng", 1: "", 2: "生日礼金", 3: "145,060" },
      { 0: "apil***527", 1: "", 2: "生日礼金", 3: "94,130" },
      { 0: "zx***55", 1: "", 2: "晋级彩金", 3: "11,188" },
      { 0: "al***23", 1: "", 2: "生日礼金", 3: "18,400" },
      { 0: "naru***2534", 1: "", 2: "晋级彩金", 3: "32,588" },
      { 0: "toy***99", 1: "", 2: "月月好运彩金", 3: "101,888" },
      { 0: "kan***asin", 1: "", 2: "月月好运彩金", 3: "21,688" },
      { 0: "m***th", 1: "", 2: "生日礼金", 3: "81,560" },
      { 0: "par***1896", 1: "", 2: "月月好运彩金", 3: "11,788" },
      { 0: "z***uhai", 1: "", 2: "负利彩金", 3: "150,701" },
      { 0: "sat***ath", 1: "", 2: "负利彩金", 3: "95,120" },
      { 0: "jne***11", 1: "", 2: "晋级彩金", 3: "203,188" },
      { 0: "sulk***law", 1: "", 2: "生日礼金", 3: "13,560" },
      { 0: "aw***528", 1: "", 2: "负利彩金", 3: "39,874" },
      { 0: "kk***70", 1: "", 2: "晋级彩金", 3: "27,088" },
      { 0: "jelen***539", 1: "", 2: "月月好运彩金", 3: "32,588" },
      { 0: "krit***jkl40", 1: "", 2: "负利彩金", 3: "12,076" },
      { 0: "ba***48", 1: "", 2: "月月好运彩金", 3: "18,458" },
      { 0: "do***290", 1: "", 2: "晋级彩金", 3: "27,088" },
    ],
    en:[
      { 0: "m**ke", 1: "", 2: "Advance Jackpot", 3: "10,238" },
      { 0: "luc****yy", 1: "", 2: "Advance Jackpot", 3: "23,888" },
      { 0: "jne***233", 1: "", 2: "Good Luck Jackpot", 3: "203,188" },
      { 0: "no***rio", 1: "", 2: "Good Luck Jackpot", 3: "13,158" },
      { 0: "pa***mee", 1: "", 2: "Good Luck Jackpot", 3: "123,132" },
      { 0: "cre***309", 1: "", 2: "Good Luck Jackpot", 3: "14,258" },
      { 0: "me***k89", 1: "", 2: "Good Luck Jackpot", 3: "27,288" },
      { 0: "kk***070", 1: "", 2: "Holiday gift", 3: "34,140" },
      { 0: "dun****t27", 1: "", 2: "Birthday gift", 3: "95,120" },
      { 0: "win***ken", 1: "", 2: "Holiday gift", 3: "34,140" },
      { 0: "me***89", 1: "", 2: "Holiday gift", 3: "34,140" },
      { 0: "ma**le", 1: "", 2: "Birthday gift", 3: "95,120" },
      { 0: "xia***ng", 1: "", 2: "Birthday gift", 3: "145,060" },
      { 0: "apil***527", 1: "", 2: "Birthday gift", 3: "94,130" },
      { 0: "zx***55", 1: "", 2: "Advance Jackpot", 3: "11,188" },
      { 0: "al***23", 1: "", 2: "Birthday gift", 3: "18,400" },
      { 0: "naru***2534", 1: "", 2: "Advance Jackpot", 3: "32,588" },
      { 0: "toy***99", 1: "", 2: "Good Luck Jackpot", 3: "101,888" },
      { 0: "kan***asin", 1: "", 2: "Good Luck Jackpot", 3: "21,688" },
      { 0: "m***th", 1: "", 2: "Birthday gift", 3: "81,560" },
      { 0: "par***1896", 1: "", 2: "Good Luck Jackpot", 3: "11,788" },
      { 0: "z***uhai", 1: "", 2: "Negative winnings", 3: "150,701" },
      { 0: "sat***ath", 1: "", 2: "Negative winnings", 3: "95,120" },
      { 0: "jne***11", 1: "", 2: "Advance Jackpot", 3: "203,188" },
      { 0: "sulk***law", 1: "", 2: "Birthday gift", 3: "13,560" },
      { 0: "aw***528", 1: "", 2: "Negative winnings", 3: "39,874" },
      { 0: "kk***70", 1: "", 2: "Advance Jackpot", 3: "27,088" },
      { 0: "jelen***539", 1: "", 2: "Good Luck Jackpot", 3: "32,588" },
      { 0: "krit***jkl40", 1: "", 2: "Negative winnings", 3: "12,076" },
      { 0: "ba***48", 1: "", 2: "Good Luck Jackpot", 3: "18,458" },
      { 0: "do***290", 1: "", 2: "Advance Jackpot", 3: "27,088" },
    ],
    yn:[
      { 0: "m**ke", 1: "", 2: "Phần thưởng khuyến mại", 3: "10,238" },
      { 0: "luc****yy", 1: "", 2: "Phần thưởng khuyến mại", 3: "23,888" },
      { 0: "jne***233", 1: "", 2: "Phần thưởng may mắn hàng tháng", 3: "203,188" },
      { 0: "no***rio", 1: "", 2: "Phần thưởng may mắn hàng tháng", 3: "13,158" },
      { 0: "pa***mee", 1: "", 2: "Phần thưởng may mắn hàng tháng", 3: "123,132" },
      { 0: "cre***309", 1: "", 2: "Phần thưởng may mắn hàng tháng", 3: "14,258" },
      { 0: "me***k89", 1: "", 2: "Phần thưởng may mắn hàng tháng", 3: "27,288" },
      { 0: "kk***070", 1: "", 2: "Phần thưởng ngày lễ", 3: "34,140" },
      { 0: "dun****t27", 1: "", 2: "Phần thưởng sinh nhật", 3: "95,120" },
      { 0: "win***ken", 1: "", 2: "Phần thưởng ngày lễ", 3: "34,140" },
      { 0: "me***89", 1: "", 2: "Phần thưởng ngày lễ", 3: "34,140" },
      { 0: "ma**le", 1: "", 2: "Phần thưởng sinh nhật", 3: "95,120" },
      { 0: "xia***ng", 1: "", 2: "Phần thưởng sinh nhật", 3: "145,060" },
      { 0: "apil***527", 1: "", 2: "Phần thưởng sinh nhật", 3: "94,130" },
      { 0: "zx***55", 1: "", 2: "Phần thưởng khuyến mại", 3: "11,188" },
      { 0: "al***23", 1: "", 2: "Phần thưởng sinh nhật", 3: "18,400" },
      { 0: "naru***2534", 1: "", 2: "Phần thưởng khuyến mại", 3: "32,588" },
      { 0: "toy***99", 1: "", 2: "Phần thưởng may mắn hàng tháng", 3: "101,888" },
      { 0: "kan***asin", 1: "", 2: "Phần thưởng may mắn hàng tháng", 3: "21,688" },
      { 0: "m***th", 1: "", 2: "Phần thưởng sinh nhật", 3: "81,560" },
      { 0: "par***1896", 1: "", 2: "Phần thưởng may mắn hàng tháng", 3: "11,788" },
      { 0: "z***uhai", 1: "", 2: "Phần thưởng lợi nhuận âm", 3: "150,701" },
      { 0: "sat***ath", 1: "", 2: "Phần thưởng lợi nhuận âm", 3: "95,120" },
      { 0: "jne***11", 1: "", 2: "Phần thưởng khuyến mại", 3: "203,188" },
      { 0: "sulk***law", 1: "", 2: "Phần thưởng sinh nhật", 3: "13,560" },
      { 0: "aw***528", 1: "", 2: "Phần thưởng lợi nhuận âm", 3: "39,874" },
      { 0: "kk***70", 1: "", 2: "Phần thưởng khuyến mại", 3: "27,088" },
      { 0: "jelen***539", 1: "", 2: "Phần thưởng may mắn hàng tháng", 3: "32,588" },
      { 0: "krit***jkl40", 1: "", 2: "Phần thưởng lợi nhuận âm", 3: "12,076" },
      { 0: "ba***48", 1: "", 2: "Phần thưởng may mắn hàng tháng", 3: "18,458" },
      { 0: "do***290", 1: "", 2: "Phần thưởng khuyến mại", 3: "27,088" },
    ],
	id:[
	  { 0: "go***8", 1: "", 2: "ZEUS", 3: "11,052" },
	  { 0: "al***23", 1: "", 2: "Brothers kingdom", 3: "54,415" },
	  { 0: "bo***990", 1: "", 2: "5 Fortune Dragons", 3: "101,141" },
	  { 0: "b***i20", 1: "", 2: "Golden Monkey", 3: "13,045" },
	  { 0: "ons***789", 1: "", 2: "Fafafa", 3: "14,025" },
	  { 0: "u7u***2549", 1: "", 2: "Joker's Treasure", 3: "45,325" },
	  { 0: "p***27", 1: "", 2: "Cai Shen 888", 3: "14,025" },
	  { 0: "s***hui", 1: "", 2: "Money Mouse", 3: "10,560" },
	  { 0: "kk***1995", 1: "", 2: "FaFaFa2", 3: "18,235" },
	  { 0: "naru***2534", 1: "", 2: "Candy Pop", 3: "50,426" },
	  { 0: "kan***asin", 1: "", 2: "Ho Yeah Monkey", 3: "12,999" },
	  { 0: "m***th", 1: "", 2: "Double Flame", 3: "18,745" },
	  { 0: "sat***ath", 1: "", 2: "Book of Myth", 3: "16,824" },
	  { 0: "jne***11", 1: "", 2: "First Love", 3: "17,050" },
	  { 0: "lerd***n11", 1: "", 2: "Dancing Fever", 3: "10,520" },
	  { 0: "z***uhai", 1: "", 2: "Lucky Koi,", 3: "60,110" },
	  { 0: "sulk***law", 1: "", 2: "Mega 7", 3: "16,524" },
	  { 0: "par***1896", 1: "", 2: "Lucky Cai Shen", 3: "12,245" },
	  { 0: "aw***528", 1: "", 2: "Heroes", 3: "17,542" },
	  { 0: "kk***70", 1: "", 2: "Baby Cai Shen", 3: "17,050" },
	  { 0: "do***290", 1: "", 2: "Golden Lotus SE", 3: "14,597" },
	  { 0: "ba***48", 1: "", 2: "First Love", 3: "15,020" },
	  { 0: "jelen***539", 1: "", 2: "Mayan Gems", 3: "15,407" },
	  { 0: "krit***jkl40", 1: "", 2: "Princess Wang", 3: "16,547" },
	  { 0: "an***a", 1: "", 2: "Pineapple Poker", 3: "14,050" },
	  { 0: "lol***8", 1: "", 2: "Fiery Sevens", 3: "13,045" },
	  { 0: "ch***at", 1: "", 2: "Dragon Empire", 3: "201,125" },
	  { 0: "ble***82128", 1: "", 2: "Three Lucky Stars", 3: "24,251" },
	  { 0: "so***30", 1: "", 2: "Sweet Bakery", 3: "131,040" },
	  { 0: "a***ong", 1: "", 2: "Magical Lamp", 3: "145,971" },
	  { 0: "kav****25", 1: "", 2: "Gold Panther", 3: "17,050" },
	  { 0: "kl***ey55", 1: "", 2: "Triple Panda", 3: "11,052" },
	  { 0: "ae***ed", 1: "", 2: "Mr Chu Tycoon", 3: "11,052" },
	  { 0: "s***sit", 1: "", 2: "Jungle King", 3: "42,050" },
	  { 0: "bo***k25", 1: "", 2: "168 Fortunes", 3: "69,999" },
	  { 0: "ye***30", 1: "", 2: "Wow Prosperity", 3: "10,560" },
	  { 0: "ons***d789", 1: "", 2: "Golden Chicken", 3: "12,235" },
	  { 0: "ka9***gnid", 1: "", 2: "Gangster Axe", 3: "17,050" },
	  { 0: "suti***2535", 1: "", 2: "Cai Yuan Guang Jin", 3: "10,560" },
	  { 0: "chi***wat", 1: "", 2: "Da Fu Xiao Fu", 3: "15,210" },
	  { 0: "de***65", 1: "", 2: "Pan Fairy", 3: "41,254" },
	  { 0: "jakkra***0093", 1: "", 2: "Highway Fortune", 3: "114,104" },
	  { 0: "aej***99", 1: "", 2: "Big Prosperity SA", 3: "17,050" },
	  { 0: "ladd***oden", 1: "", 2: "chompoo", 3: "17,050" },
	  { 0: "toey***rvive", 1: "", 2: "Tiger Warrior", 3: "47,452" },
	  { 0: "xx***16", 1: "", 2: "Double Fortunes", 3: "10,502" },
	  { 0: "ch***26", 1: "", 2: "Golden Whale", 3: "10,105" },
	  { 0: "lad***den", 1: "", 2: "Alibaba", 3: "16,652" },
	  { 0: "zx***55", 1: "", 2: "Fist of Gold", 3: "17,050" },
	  { 0: "bu***i20", 1: "", 2: "Emperor Gate SA", 3: "11,052" },
	  { 0: "mr***5", 1: "", 2: "Book of Dead", 3: "134,055" },
	  { 0: "the***31", 1: "", 2: "Mystery Joker", 3: "28,854" },
	  { 0: "pp***986", 1: "", 2: "Golden Ticket 2", 3: "10,501" },
	  { 0: "tnmi***51", 1: "", 2: "Temple of Wealth", 3: "124,251" },
	  { 0: "love***64", 1: "", 2: "Celebration of Wealth", 3: "47,452" },
	  { 0: "advc***h", 1: "", 2: "Fire Joker", 3: "108,521" },
	  { 0: "pol***4", 1: "", 2: "GEMiX", 3: "45,254" },
	  { 0: "zonr***n1", 1: "", 2: "Energoonz", 3: "404,272" },
	  { 0: "opl***24", 1: "", 2: "Big Win Cat", 3: "148,247" },
	  { 0: "ton***68", 1: "", 2: "Xmas Joker", 3: "14,529" },
	  { 0: "bo***23722", 1: "", 2: "Goblin Treasure", 3: "10,560" },
	  { 0: "jo***230", 1: "", 2: "Dirt Bike", 3: "15,811" },
	  { 0: "ye***30", 1: "", 2: "Dog Racing", 3: "10,250" },
	  { 0: "nn***42", 1: "", 2: "asure", 3: "10,250" },
	  { 0: "kk09***5183", 1: "", 2: "Goblin Treasure", 3: "50,011" },
	  { 0: "ps***4", 1: "", 2: "Goblin Treasure", 3: "17,050" },
	  { 0: "n***08", 1: "", 2: "Goblin Treasure", 3: "12,524" },
	  { 0: "kak***01", 1: "", 2: "Goblin Treasure", 3: "134,055" },
	  { 0: "a***8046", 1: "", 2: "asure", 3: "12,025" },
	  { 0: "ra***at", 1: "", 2: "Magic Journey", 3: "50,426" },
	  { 0: "nic***993", 1: "", 2: "Dog Racing", 3: "31,005" },
	  { 0: "ven***90", 1: "", 2: "PinBall Fortune", 3: "81,205" },
	  { 0: "tant***21", 1: "", 2: "Dragon Clan", 3: "25,416" },
	  { 0: "van***eree", 1: "", 2: "Dragon Gold SA", 3: "17,050" },
	  { 0: "r***57", 1: "", 2: "Great Stars SA", 3: "41,857" },
	  { 0: "sug***987", 1: "", 2: "Iceland SA", 3: "36,535" },
	  { 0: "tayj***20", 1: "", 2: "Lucky Feng Shui", 3: "75,025" },
	  { 0: "q1w***4t5", 1: "", 2: "Wong Choy SA", 3: "12,245" },
	  { 0: "glum***980", 1: "", 2: "5 Fortune SA", 3: "17,542" },
	  { 0: "ta***9", 1: "", 2: "King Pharaoh", 3: "80,521" },
	  { 0: "po***07", 1: "", 2: "Mermaid", 3: "14,597" },
	  { 0: "teera***n2520", 1: "", 2: "Wong Po", 3: "95,479" },
	  { 0: "bun***37", 1: "", 2: "Adventure Iceland", 3: "132,023" },
	  { 0: "thi***43", 1: "", 2: "Lucky Baby", 3: "42,540" },
	  { 0: "son***am87", 1: "", 2: "Great Stars SA", 3: "15,420" },
	  { 0: "pat***11", 1: "", 2: "Lucky Strike", 3: "245,985" },
	  { 0: "ro***ma", 1: "", 2: "Railway King", 3: "75,421" },
	  { 0: "ya***y27", 1: "", 2: "Spin Stone", 3: "25,647" },
	  { 0: "tu***35", 1: "", 2: "Wong Choy", 3: "345,887" },
	  { 0: "ben***526", 1: "", 2: "5 Fortune", 3: "41,254" },
	  { 0: "taw***0444", 1: "", 2: "Master Chef", 3: "102,495" },
	  { 0: "ong***man", 1: "", 2: "Moon Princess", 3: "45,967" },
	  { 0: "par***96", 1: "", 2: "Rise of Olympus", 3: "65,624" },
	  { 0: "ve***290", 1: "", 2: "Tome of Madness", 3: "17,050" },
	  { 0: "ar***80", 1: "", 2: "Golden Ticket", 3: "15,665" },
	  { 0: "ban***57", 1: "", 2: "Reactoonz", 3: "34,457" },
	  { 0: "m9***82", 1: "", 2: "BlackJack MH", 3: "12,235" },
	  { 0: "tw***ve", 1: "", 2: "Reactoonz 2", 3: "17,954" },
	  { 0: "fdal***97", 1: "", 2: "Sweet Alchemy", 3: "164,157" },
	  { 0: "you***6", 1: "", 2: "Honey Rush", 3: "94,636" }
	],
	
}
